import { useState } from "react";
import Layout from "../../components/layout/Layout";
import useCategory from "../../hooks/useCategory";
import { requestHandler, uploadFileHandler } from "../../utils/requestHandler";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Image from "../../components/elements/Image";
import { IMG_BASE_URL } from "../../utils/constants";

function Categories() {
  const { categories, fetchCategories, fetchCategoryDetail, categoryDetail } =
    useCategory();
  const navigate = useNavigate();
  const [edit, setEdit] = useState({
    editEnable: false,
    editId: "",
  });

  const formik = useFormik({
    initialValues: {
      categoryName: "",
      image: "",
    },
    onSubmit: (values, { resetForm }) => {
      if (!edit.editEnable) {
        requestHandler({
          endpoint: "/api/v1/category",
          method: "POST",
          body: {
            ...values,
            parent: categoryDetail ? categoryDetail._id : undefined,
          },
        })
          .then((res) => {
            if (res.success) {
              alert(res.message);
              fetchCategories();
              resetForm();
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      } else {
        requestHandler({
          endpoint: "/api/v1/category",
          method: "PUT",
          body: {
            ...values,
            categoryId: edit.editId,
          },
        })
          .then((res) => {
            if (res.success) {
              alert(res.message);
              fetchCategories();
              resetForm();
              setEdit({ editEnable: false, editId: "" });
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      }
    },
  });

  const handleDelete = async (id: string) => {
    requestHandler({
      endpoint: "/api/v1/category",
      method: "DELETE",
      body: { categoryId: id },
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          fetchCategories();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const viewSubCategory = (slug: string) => {
    fetchCategoryDetail({ slug });
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let data = new FormData();
      data.append("file", e.target.files[0]);
      data.append("path", "Category/images");

      uploadFileHandler<string>({
        endpoint: "/api/v1/upload",
        body: data,
      })
        .then((response) => {
          if (response.data)
            formik.setFieldValue("image", `${IMG_BASE_URL}/${response.data}`);
        })
        .catch((err) => {
          alert(err.message || "Error uploading in image try again!!");
        });

      // console.log(response.data);
    }
  };

  const deleteImage = () => {
    formik.setFieldValue("image", "");
  };
  return (
    <Layout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">
              {categoryDetail ? categoryDetail.categoryName : "Categories"}{" "}
            </h2>
            <p>Add, edit or delete a category</p>
          </div>
          {/* <div>
            <input
              type="text"
              placeholder="Search Categories"
              className="form-control bg-white"
            />
          </div> */}
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-4">
                    <label htmlFor="categoryName" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      id="categoryName"
                      name="categoryName"
                      value={formik.values.categoryName}
                      onChange={formik.handleChange}
                    />
                  </div>
                  {/* <div className="mb-4">
                    <label htmlFor="slug" className="form-label">
                      Slug
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      id="slug"
                      name="slug"
                      value={formik.values.slug}
                      onChange={formik.handleChange}
                    />
                  </div> */}
                  <div className="mb-4">
                    <label htmlFor="image" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      placeholder="Type here"
                      className="form-control"
                      id="image"
                      // name="image"
                      accept="image/*"
                      // value={formik.values.slug}
                      onChange={onFileChange}
                    />
                  </div>
                  {formik.values.image && (
                    <Image src={formik.values.image} onDelete={deleteImage} />
                  )}

                  <div className="d-grid text-center">
                    <button type="submit" className="btn btn-primary">
                      {edit.editEnable ? "Update category" : "Create category"}
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-md-9">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                            />
                          </div>
                        </th>
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Image</th>
                        <th>Slug</th>
                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categories.map((category, i) => {
                        return (
                          <tr key={category._id}>
                            <td className="text-center">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                />
                              </div>
                            </td>
                            <td>{i + 1}</td>
                            <td>{category.categoryName}</td>
                            <td>
                              <Image deleteShow={false} src={category.image} />
                            </td>

                            <td> /{category.slug}</td>
                            <td className="text-end">
                              <div className="dropdown">
                                <a
                                  href="#"
                                  data-bs-toggle="dropdown"
                                  className="btn btn-light rounded btn-sm font-sm"
                                >
                                  {" "}
                                  <i className="material-icons md-more_horiz" />{" "}
                                </a>
                                <div className="dropdown-menu">
                                  <div
                                    className="dropdown-item"
                                    onClick={() =>
                                      viewSubCategory(category.slug)
                                    }
                                  >
                                    View/Add Subcategories
                                  </div>
                                  <div
                                    className="dropdown-item"
                                    onClick={() => {
                                      formik.setFieldValue(
                                        "categoryName",
                                        category.categoryName
                                      );
                                      formik.setFieldValue(
                                        "image",
                                        category.image
                                      );
                                      setEdit((prev) => ({
                                        ...prev,
                                        editEnable: true,
                                        editId: category._id,
                                      }));
                                    }}
                                  >
                                    Edit info
                                  </div>
                                  <div
                                    className="dropdown-item text-danger"
                                    onClick={() => handleDelete(category._id)}
                                  >
                                    Delete
                                  </div>
                                </div>
                              </div>
                              {/* dropdown //end */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* .col// */}
            </div>
            {/* .row // */}
          </div>
          {/* card body .// */}
        </div>
        {/* card .// */}
      </section>
    </Layout>
  );
}

export default Categories;
