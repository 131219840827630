import React, { useEffect, useState, useRef } from "react";
import Layout from "../../components/layout/Layout";
import { useParams } from "react-router-dom";
import {
  Categories,
  IMaterialSchema,
  IPrintSchema,
  MaskedImageI,
  PrintingMethodI,
  Product,
} from "../../interfaces/productInterfaces";
import { useFormik } from "formik";
import { requestHandler, uploadFileHandler } from "../../utils/requestHandler";
import useCategory from "../../hooks/useCategory";
import { IMG_BASE_URL } from "../../utils/constants";
import Swal from "sweetalert2";
import Image from "../../components/elements/Image";
import useGet from "../../hooks/useGet";
import Editor from "../../components/elements/Editor";
import currencies from "../../utils/currencies.json"

function ProductForm() {
  const { slug } = useParams();
  const { categories, fetchCategoryDetail, categoryDetail } = useCategory();
  const printingMethods = useGet<PrintingMethodI>("/api/v1/printing-method");
  const [images, setImages] = useState<string[]>([]);
  const [maskedImages, setMaskedImages] = useState<MaskedImageI[]>([
    { side: "", src: "" },
  ]);
  const [edit, setEdit] = useState({
    editEnable: false,
    editId: "",
  });
  const colorRef = useRef<HTMLInputElement>(null);
  const sizeRef = useRef<HTMLInputElement>(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      specification: "",
      details: "",
      price: 0,
      minimumQuantity: 1,
      maxQuantity: 0,
      promotionalPrice: 0,
      currency: "USD",
      category: "",
      reference: "admin",
      shippingFrom:"",
      isCustomizable: false,
      colors: [] as string[],
      sizes: [] as string[],
      printingMethods: [] as IPrintSchema[],
      materials: [] as IMaterialSchema[],
    },
    onSubmit: (values, { resetForm }) => {
      if (!edit.editEnable) {
        requestHandler({
          endpoint: "/api/v1/products",
          method: "POST",
          body: {
            ...values,
            images,
            maskedImages: maskedImages.filter(
              (mask) => mask.side !== "" || mask.src !== ""
            ),
          },
        })
          .then((res) => {
            if (res.success) {
              alert(res.message);
              resetForm();
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      } else {
        requestHandler({
          endpoint: "/api/v1/products",
          method: "PUT",
          body: {
            ...values,
            images,
            maskedImages: maskedImages.filter(
              (mask) => mask.side !== "" || mask.src !== ""
            ),
            productId: edit.editId,
          },
        })
          .then((res) => {
            if (res.success) {
              alert(res.message);
              // resetForm();
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      }
    },
  });

  const fetchProductDetail = (slug: string) => {
    requestHandler<Product>({
      endpoint: `/api/v1/products/${slug}`,
      method: "GET",
    })
      .then((res) => {
        if (res.success) {
          if (res.data.category.parent)
            fetchCategoryDetail({ categoryId: res.data.category.parent });

          formik.setValues({
            name: res.data.name,
            description: res.data.description,
            specification: res.data.specification || "",
            details: res.data.details || "",
            price: res.data.price,
            promotionalPrice: res.data.promotionalPrice,
            currency: res.data.currency,
            category: res.data.category._id,
            reference: "admin",
            isCustomizable: res.data.isCustomizable,
            sizes: res.data.sizes,
            colors: res.data.colors,
            printingMethods:
              res.data.printingMethods?.map((item) => ({
                printingMethod: item.printingMethod._id,
                price: item.price,
              })) || [],
            materials: res.data.materials || [],
            minimumQuantity: res.data.minimumQuantity || 0,
            maxQuantity: res.data.maxQuantity || 0,
            shippingFrom:res.data.shippingFrom
          });
          setEdit((prev) => ({ ...prev, editId: res.data._id }));
          setImages(res.data.images);
          setMaskedImages(res.data.maskedImages);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (slug) {
      setEdit({ editEnable: true, editId: "" });
      fetchProductDetail(slug);
    }
  }, [slug]);

  const categoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    const selectedCategory = categories.find((item) => item._id === value);
    if (selectedCategory && selectedCategory.subcategories.length > 0) {
      fetchCategoryDetail({ slug: selectedCategory.slug });
    } else {
      console.log(e);

      formik.handleChange(e);
    }
  };
  const onFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "product" | "mask"
  ) => {
    if (e.target.files) {
      let data = new FormData();
      data.append("file", e.target.files[0]);
      if (type === "product") data.append("path", "product/images");
      if (type === "mask") data.append("path", "masked/images");

      uploadFileHandler<string>({
        endpoint: "/api/v1/upload",
        body: data,
      })
        .then((response) => {
          if (response.success) {
            if (type === "product")
              setImages((prev) => [
                `${IMG_BASE_URL}/${response.data}`,
                ...prev,
              ]);
            if (type === "mask") {
            }
            // setMaskedImages((prev) => [
            //   ...prev,
            //   `${IMG_BASE_URL}/${response.data}`,
            // ]);
          }
        })
        .catch(() => {});

      // console.log(response.data);
    }
  };

  function extractImagePath(imageUrl: string) {
    const regex = /https:\/\/impressiful\.s3\.eu-north-1\.amazonaws\.com\/(.*)/;
    const match = imageUrl.match(regex);
    return match ? match[1] : "";
  }
  const delteImage = (image: string, type: "product" | "mask") => {
    let path = extractImagePath(image);
    if (path) {
      requestHandler({
        endpoint: "/api/v1/deleteFile",
        method: "DELETE",
        body: { file: path },
      })
        .then(async (res) => {
          if (res.success) {
            // alert(res.message);
            let updatedImages: string[] = [];
            if (type === "product")
              updatedImages = images.filter((item) => item !== image);
            setImages(updatedImages);
            // if (type === "mask")
            //   if (edit.editId && edit.editEnable) {
            //     // updatedImages = maskedImages.filter((item) => item !== image);

            //     updateImages(updatedImages, type);
            //   } else {
            //     if (type === "product") setImages(updatedImages);
            //     // if (type === "mask") setMaskedImages(updatedImages);
            //   }
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    } else {
      let updatedImages: string[] = [];
      if (type === "product")
        updatedImages = images.filter((item) => item !== image);
      updateImages(updatedImages, type);

      // if (type === "mask")
      //   if (edit.editId && edit.editEnable) {
      //     // updatedImages = maskedImages.filter((item) => item !== image);
      //   }
    }
  };
  const updateImages = async (
    updatedImages: string[],
    type: "product" | "mask"
  ) => {
    let data: any = {};
    data.productId = edit.editId;
    if (type === "product") data.images = updatedImages;
    if (type === "mask") data.maskedImages = updatedImages;

    await requestHandler({
      endpoint: "/api/v1/products",
      method: "PUT",
      body: data,
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          if (type === "product") setImages(updatedImages);
          // if (type === "mask") setMaskedImages(updatedImages);
          // resetForm();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const onDeleteCLick = (image: string, type: "product" | "mask") => {
    if (!edit.editEnable) return delteImage(image, type);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        delteImage(image, type);
        // Swal.fire({
        //   title: "Deleted!",
        //   text: "Your file has been deleted.",
        //   icon: "success"
        // });
      }
    });
  };
  // for masking images

  const addSidesInMasked = () => {
    setMaskedImages((prev) => [...prev, { side: "", src: "" }]);
  };

  const makedImageUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (!e.target.files) return;

    let data = new FormData();
    data.append("file", e.target.files[0]);
    data.append("path", "masked/images");

    uploadFileHandler<string>({
      endpoint: "/api/v1/upload",
      body: data,
    })
      .then((response) => {
        if (response.success) {
          let updated = [...maskedImages];
          updated[index].src = `${IMG_BASE_URL}/${response.data}`;
          setMaskedImages(updated);
        }
      })
      .catch(() => {});
  };

  const onPrintingMethodSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      let updatedValue = formik.values.printingMethods;
      updatedValue.push({ printingMethod: e.target.value, price: 0 });
      formik.setFieldValue("printingMethods", updatedValue);
    } else {
      let updatedValue = formik.values.printingMethods.filter(
        (method) => method.printingMethod !== e.target.value
      );
      formik.setFieldValue("printingMethods", updatedValue);
    }
  };

  const onPrintingMethodPrice = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    let updatedValue = formik.values.printingMethods.map((item) =>
      item.printingMethod === id
        ? { ...item, price: parseInt(e.target.value) }
        : item
    );
    formik.setFieldValue("printingMethods", updatedValue);
  };
  const methodCheck = (method: string) => {
    return formik.values.printingMethods.find(
      (item) => item.printingMethod === method
    );
  };
  return (
    <Layout>
      <section className="content-main">
        <form onSubmit={formik.handleSubmit} className="row">
          <div className="col-9">
            <div className="content-header">
              <h2 className="content-title">
                {edit.editEnable ? "Update" : "Add New"} Product
              </h2>
              <div>
                <button
                  type="submit"
                  className="btn btn-md rounded font-sm hover-up"
                >
                  {edit.editEnable ? "Update" : "Submit"}
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card mb-4">
              <div className="card-header">
                <h4>Basic</h4>
              </div>
              <div className="card-body">
                {/* <form> */}
                <div className="mb-4">
                  <label htmlFor="product_name" className="form-label">
                    Product title
                  </label>
                  <input
                    type="text"
                    placeholder="Type here"
                    className="form-control"
                    id="product_name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="form-label">Full description</label>
                  <Editor
                    value={formik.values.description}
                    onChange={(e, editor) => {
                      formik.setFieldValue("description", editor.getData());
                    }}
                  />
                  {/* <textarea
                    placeholder="Type here"
                    className="form-control"
                    rows={4}
                    defaultValue={""}
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                  /> */}
                </div>
                <div className="mb-4">
                  <label className="form-label">Product specification</label>
                  <Editor
                    value={formik.values.specification}
                    onChange={(e, editor) => {
                      formik.setFieldValue("specification", editor.getData());
                    }}
                  />
                  {/* <textarea
                    placeholder="Type here"
                    className="form-control"
                    rows={4}
                    defaultValue={""}
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                  /> */}
                </div>
                <div className="mb-4">
                  <label className="form-label">Product Details</label>
                  <Editor
                    value={formik.values.details}
                    onChange={(e, editor) => {
                      formik.setFieldValue("details", editor.getData());
                    }}
                  />
                  {/* <textarea
                    placeholder="Type here"
                    className="form-control"
                    rows={4}
                    defaultValue={""}
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                  /> */}
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-4">
                      <label className="form-label"> price</label>
                      <div className="row gx-2">
                        <input
                          placeholder="$"
                          type="text"
                          className="form-control"
                          name="price"
                          value={formik.values.price}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-4">
                      <label className="form-label">Promotional price</label>
                      <input
                        placeholder="$"
                        type="text"
                        name="promotionalPrice"
                        value={formik.values.promotionalPrice}
                        onChange={formik.handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-4">
                      <label className="form-label">Minimum quantity</label>
                      <input
                        placeholder="enter"
                        type="number"
                        name="minimumQuantity"
                        value={formik.values.minimumQuantity}
                        onChange={formik.handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-4">
                      <label className="form-label">Maximum quantity</label>
                      <input
                        placeholder="enter"
                        type="number"
                        name="maxQuantity"
                        value={formik.values.maxQuantity}
                        onChange={formik.handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">Currency</label>
                    <select
                      className="form-select"
                      value={formik.values.currency}
                      onChange={formik.handleChange}
                      name="currency"
                    >
                      <option disabled value={""}>
                        Select
                      </option>
                     {Object.keys(currencies).map((item)=>{
                      return <option value={item} key={item}>{item}</option>
                     })} 
                      {/* <option value="EUR">EUR</option>
                      <option value={"AED"}>AED</option> */}
                    </select>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-4">
                      <label className="form-label">Shipping From</label>
                      <input
                        placeholder="enter"
                        type="text"
                        name="shippingFrom"
                        value={formik.values.shippingFrom}
                        onChange={formik.handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <label className="form-check mb-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="isCustomizable"
                    checked={formik.values.isCustomizable}
                    onChange={formik.handleChange}
                  />
                  <span className="form-check-label"> Is Customizable </span>
                </label>
                {formik.values.isCustomizable &&
                  maskedImages.map((masked, i) => {
                    return (
                      <div className="row" key={i}>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="form-label">Side</label>
                            <input
                              type="text"
                              className="form-control"
                              value={masked.side}
                              onChange={(e) => {
                                let updated = [...maskedImages];
                                updated[i].side = e.target.value;
                                setMaskedImages(updated);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="form-label">
                              Upload masked images
                            </label>
                            <input
                              accept="image/*"
                              type="file"
                              className="form-control"
                              onChange={(e) => makedImageUpload(e, i)}
                            />
                          </div>
                          {masked.src && (
                            <Image
                              src={masked.src}
                              deleteShow={i > 0}
                              onDelete={() => {
                                let updated = [...maskedImages];
                                updated.splice(i, 1);
                                setMaskedImages(updated);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                {formik.values.isCustomizable && (
                  <div
                    className="btn btn-md rounded font-sm hover-up"
                    onClick={addSidesInMasked}
                  >
                    Add more side
                  </div>
                )}
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-header">
                <h4>Printing Methods</h4>
              </div>
              <div className="card-body">
                {printingMethods.data.map((method, i) => {
                  return (
                    <div className="row" key={method._id}>
                      <div className="col-lg-6 d-flex align-items-center ">
                        <div className="">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={Boolean(methodCheck(method._id))}
                            value={method._id}
                            onChange={onPrintingMethodSelect}
                          />
                          <span className="form-check-label">
                            {" "}
                            {method.methodName}
                          </span>
                        </div>
                      </div>
                      {/* {methodCheck(method._id) && (
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label htmlFor="price" className="form-label">
                              Price
                            </label>
                            <input
                              type="text"
                              placeholder="price"
                              className="form-control"
                              id="product_name"
                              value={methodCheck(method._id)?.price || 0}
                              onChange={(e) =>
                                onPrintingMethodPrice(e, method._id)
                              }
                            />
                          </div>
                        </div>
                      )} */}
                    </div>
                  );
                })}
                {/* <div className="mb-4">
                    <label htmlFor="product_name" className="form-label">
                      Weight
                    </label>
                    <input
                      type="text"
                      placeholder="gam"
                      className="form-control"
                      id="product_name"
                    />
                  </div> */}
                {/* <div className="mb-4">
                    <label htmlFor="product_name" className="form-label">
                      Shipping fees
                    </label>
                    <input
                      type="text"
                      placeholder="$"
                      className="form-control"
                      id="product_name"
                    />
                  </div> */}
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-header d-flex justify-content-between">
                <h4>Product Materials</h4>
                <button
                  type="button"
                  className="btn btn-md rounded font-sm hover-up"
                  onClick={() => {
                    let materials = [...formik.values.materials];
                    materials.push({ material: "", price: 0 });
                    formik.setFieldValue("materials", materials);
                  }}
                >
                  +
                </button>
              </div>
              <div className="card-body">
                {formik.values.materials.map((material, i) => {
                  return (
                    <div className="row" key={i}>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label"> Material Name</label>
                          <div className="row gx-2">
                            <input
                              placeholder="material"
                              type="text"
                              className="form-control"
                              // name="price"
                              value={material.material}
                              onChange={(e) => {
                                let materials = [...formik.values.materials];
                                materials[i].material = e.target.value;
                                formik.setFieldValue("materials", materials);
                              }}
                              // onChange={formik.handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label">Material price</label>
                          <input
                            placeholder="$"
                            type="number"
                            // name="promotionalPrice"
                            value={material.price}
                            onChange={(e) => {
                              let materials = [...formik.values.materials];
                              materials[i].price = parseInt(e.target.value);
                              formik.setFieldValue("materials", materials);
                            }}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 d-flex align-items-center ">
                        <button
                          type="button"
                          className="btn btn-danger font-sm"
                          onClick={() => {
                            let materials = [...formik.values.materials];
                            materials.splice(i, 1);
                            formik.setFieldValue("materials", materials);
                          }}
                        >
                          {" "}
                          -
                        </button>
                      </div>
                      {/* <div className="col-lg-6">
                      <label className="form-label">Currency</label>
                      <select
                        className="form-select"
                        value={formik.values.currency}
                        onChange={formik.handleChange}
                        name="currency"
                      >
                        <option disabled value={""}>
                          Select
                        </option>
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                        <option value={"AED"}>AED</option>
                      </select>
                    </div> */}
                    </div>
                  );
                })}
                {/* <div className="mb-4">
                    <label htmlFor="product_name" className="form-label">
                      Weight
                    </label>
                    <input
                      type="text"
                      placeholder="gam"
                      className="form-control"
                      id="product_name"
                    />
                  </div> */}
                {/* <div className="mb-4">
                    <label htmlFor="product_name" className="form-label">
                      Shipping fees
                    </label>
                    <input
                      type="text"
                      placeholder="$"
                      className="form-control"
                      id="product_name"
                    />
                  </div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="card mb-4">
              <div className="card-header">
                <h4>Media</h4>
              </div>
              <div className="card-body">
                <div className="input-upload">
                  <img src="/assets/imgs/theme/upload.svg" alt="" />
                  <input
                    className="form-control"
                    accept="image/*"
                    type="file"
                    onChange={(e) => onFileChange(e, "product")}
                  />
                </div>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-header">
                <h4> Categories</h4>
              </div>
              <div className="card-body">
                <div className="row gx-2">
                  <div className="col-12">
                    <div className="mb-4">
                      {categories.map((category) => {
                        return (
                          <label
                            className="mb-2 form-check form-check-inline"
                            key={category._id}
                          >
                            <input
                              className="form-check-input"
                              name="category"
                              type="radio"
                              value={category._id}
                              checked={formik.values.category === category._id}
                              onChange={categoryChange}
                            />
                            <span className="form-check-label">
                              {" "}
                              {category.categoryName}{" "}
                            </span>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-header">
                <h4>Colors</h4>
              </div>
              <div className="card-body">
                <div className="input-upload mb-4 d-flex gap-2">
                  <input className="form-control" type="color" ref={colorRef} />
                  <button
                    type="button"
                    className="btn btn-md rounded font-sm hover-up"
                    onClick={() => {
                      if (colorRef.current?.value)
                        formik.setFieldValue("colors", [
                          ...formik.values.colors,
                          colorRef.current.value,
                        ]);
                    }}
                  >
                    Add
                  </button>
                </div>
                <div className="d-flex flex-wrap gap-2">
                  {formik.values.colors.map((color, i) => {
                    return (
                      <div
                        key={i}
                        className="hover-up d-flex justify-content-center color-box"
                        style={{
                          height: "35px",
                          width: "35px",
                          borderRadius: "5px",
                          background: color,
                          border: "1px solid black",
                        }}
                      >
                        <button
                          type="button"
                          className="btn "
                          onClick={() => {
                            let updatedColors = [...formik.values.colors];
                            updatedColors.splice(i, i);
                            formik.setFieldValue("colors", updatedColors);
                          }}
                        >
                          <i
                            style={{ color: "red" }}
                            className="icon material-icons md-remove_circle "
                          ></i>
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-header">
                <h4>Sizes</h4>
              </div>
              <div className="card-body">
                <div className=" mb-4 d-flex gap-2">
                  <input className="form-control" type="text" ref={sizeRef} />
                  <button
                    type="button"
                    className="btn btn-md rounded font-sm hover-up"
                    onClick={() => {
                      if (sizeRef.current?.value)
                        formik.setFieldValue("sizes", [
                          ...formik.values.sizes,
                          sizeRef.current.value,
                        ]);
                    }}
                  >
                    Add
                  </button>
                </div>
                <div className="d-flex flex-wrap gap-2">
                  {formik.values.sizes.map((size, i) => {
                    return (
                      <div
                        key={i}
                        className="hover-up d-flex justify-content-center align-items-center color-box "
                        style={{
                          height: "35px",
                          width: "35px",
                          borderRadius: "5px",
                          border: "1px solid black",
                        }}
                      >
                        {size}
                        <button
                          type="button"
                          style={{ position: "absolute" }}
                          className="btn"
                          onClick={() => {
                            let updatedSizes = [...formik.values.sizes];
                            updatedSizes.splice(i, i);
                            formik.setFieldValue("sizes", updatedSizes);
                          }}
                        >
                          <i
                            style={{ color: "red" }}
                            className="icon material-icons md-remove_circle "
                          ></i>
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {/* <div className="card mb-4">
              <div className="card-header">
                <h4>Materials</h4>
              </div>
              <div className="card-body">
                <div className=" mb-4 d-flex gap-2">
                  <input className="form-control" type="text" ref={sizeRef} />
                  <button
                    type="button"
                    className="btn btn-md rounded font-sm hover-up"
                    onClick={() => {
                      if (sizeRef.current?.value)
                        formik.setFieldValue("sizes", [
                          ...formik.values.sizes,
                          sizeRef.current.value,
                        ]);
                    }}
                  >
                    Add
                  </button>
                </div>
             
              </div>
            </div> */}

            {/* card end// */}
          </div>
          <div className="col-lg-3">
            {/* <div className="d-flex"> */}
            {images.map((image, i) => {
              return (
                <div
                  className="card"
                  style={{ height: "100px", width: "100px" }}
                >
                  <button
                    type="button"
                    className="btn delete-image"
                    onClick={() => onDeleteCLick(image, "product")}
                  >
                    <i
                      style={{ color: "red" }}
                      className="icon material-icons md-remove_circle "
                    ></i>
                  </button>

                  <img
                    key={i}
                    src={image}
                    alt="product"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              );
            })}
          </div>

          {/* </div> */}
        </form>
      </section>
    </Layout>
  );
}

export default ProductForm;
