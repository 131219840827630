import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { useFormik } from "formik";
import { requestHandler, uploadFileHandler } from "../../utils/requestHandler";
import Image from "../../components/elements/Image";
import { IMG_BASE_URL } from "../../utils/constants";
import { BannerI, HeadlineI } from "../../interfaces/productInterfaces";
function Headline() {
  const [headline, setHeadline] = useState<HeadlineI[]>([]);
  const [edit, setEdit] = useState({
    editEnable: false,
    editId: "",
  });
  const formik = useFormik({
    initialValues: {
      headline: "",
    },
    onSubmit: (values, { resetForm }) => {
      requestHandler({
        endpoint: "/api/v1/headline",
        method: "POST",
        body: {
          ...values,
        },
      })
        .then((res) => {
          if (res.success) {
            getAllHeadline();
            alert(res.message);
            resetForm();
            setEdit((prev)=>({
              editEnable:false,
              editId:""
            }))
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    },
  });

  const deleteImage = () => {
    formik.setFieldValue("imageUrl", "");
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let data = new FormData();
      data.append("file", e.target.files[0]);
      data.append("path", "headline/images");

      uploadFileHandler<string>({
        endpoint: "/api/v1/upload",
        body: data,
      })
        .then((response) => {
          if (response.data)
            formik.setFieldValue(
              "imageUrl",
              `${IMG_BASE_URL}/${response.data}`
            );
        })
        .catch((err) => {
          alert(err.message || "Error uploading in image try again!!");
        });

      // console.log(response.data);
    }
  };

  const handleStatus = async (status: boolean) => {
    requestHandler({
      endpoint: `/api/v1/headline`,
      method: "POST",
      body:{isActive:status},
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          getAllHeadline();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const getAllHeadline = useCallback(async () => {
    requestHandler<HeadlineI[]>({
      endpoint: "/api/v1/headline",
      method: "GET",
    })
      .then((res) => {
        if (res.success) {
          setHeadline(res.data);
        }
      })
      .catch((err) => {
        // alert(err.message);
      });
  }, []);

  useEffect(() => {
    getAllHeadline();
  }, [getAllHeadline]);
  return (
    <Layout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">Headline</h2>
            <p>Add, edit or delete a Headline</p>
          </div>
          {/* <div>
        <input
          type="text"
          placeholder="Search Categories"
          className="form-control bg-white"
        />
      </div> */}
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
             {edit.editEnable&& <div className="col-md-3">
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-4">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <textarea
                      placeholder="Type here"
                      className="form-control"
                      id="headline"
                      name="headline"
                      value={formik.values.headline}
                      onChange={formik.handleChange}
                    />
                  </div>

                  <div className="d-grid text-center">
                    <button type="submit" className="btn btn-primary">
                      Update Headline
                    </button>
                  </div>
                </form>
              </div>}
              <div className={`col-md-${edit.editEnable?"9":"12"}`}>
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                            />
                          </div>
                        </th>
                        <th>S.No</th>
                        <th>Headline</th>
                        <th>Status</th>

                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {headline.map((banner, i) => {
                        return (
                          <tr key={banner._id}>
                            <td className="text-center">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                />
                              </div>
                            </td>
                            <td>{i + 1}</td>
                            <td>{banner.headline}</td>
                            <td>
                              {" "}
                              <span>{banner.isActive?"Active":"Deactive"}</span>
                            </td>

                            <td className="text-end">
                              <div className="dropdown">
                                <a
                                  href="#"
                                  data-bs-toggle="dropdown"
                                  className="btn btn-light rounded btn-sm font-sm"
                                >
                                  {" "}
                                  <i className="material-icons md-more_horiz" />{" "}
                                </a>
                                <div className="dropdown-menu">
                                  {/* <div
                                className="dropdown-item"
                                onClick={() =>
                                  viewSubCategory(category.slug)
                                }
                              >
                                View/Add Subcategories
                              </div> */}
                                  <div
                                    className="dropdown-item"
                                    onClick={() => {
                                      formik.setFieldValue(
                                        "headline",
                                        banner.headline
                                      );

                                      setEdit((prev) => ({
                                        ...prev,
                                        editEnable: true,
                                        editId: banner._id,
                                      }));
                                    }}
                                  >
                                    Edit info
                                  </div>
                                  <div
                                    className="dropdown-item text-danger"
                                    onClick={() => handleStatus(!banner.isActive)}
                                  >
                                    Change Status
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* .col// */}
            </div>
            {/* .row // */}
          </div>
          {/* card body .// */}
        </div>
        {/* card .// */}
      </section>
    </Layout>
  );
}

export default Headline;
