import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { useFormik } from "formik";
import { requestHandler, uploadFileHandler } from "../../utils/requestHandler";
import Image from "../../components/elements/Image";
import { IMG_BASE_URL } from "../../utils/constants";
import {
  AboutusCardContentsI,
  AboutusI,
  PrivacyPolicyI,
} from "../../interfaces/productInterfaces";

import Editor from "../../components/elements/Editor";
function PrivacyPolicy() {
  const [privacypolicy, setPrivacyPolicy] = useState("");

  const getAllDesigns = useCallback(async () => {
    requestHandler<PrivacyPolicyI>({
      endpoint: `/api/v1/privacy-policy`,
      method: "GET",
    })
      .then((res) => {
        if (res.success) {
          setPrivacyPolicy(res.data.content);
        }
      })
      .catch((err) => {
        // alert(err.message);
      });
  }, []);

  useEffect(() => {
    getAllDesigns();
  }, [getAllDesigns]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    requestHandler({
      endpoint: "/api/v1/privacy-policy",
      method: "PUT",
      body: {
        content: privacypolicy,
      },
    })
      .then((res) => {
        if (res.success) {
          getAllDesigns();
          alert(res.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  return (
    <Layout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">Privacy Policy</h2>
            <p>Add, edit or delete a Privacy Policy</p>
          </div>
          {/* <div>
          <input
            type="text"
            placeholder="Search Categories"
            className="form-control bg-white"
          />
        </div> */}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 mb-4">
                  <label htmlFor="aboutusDescription" className="form-label">
                    About us description
                  </label>
                  <Editor
                    value={privacypolicy}
                    onChange={(e, editor) => setPrivacyPolicy(editor.getData())}
                  />
                </div>
              </div>

              <div className="">
                <div>
                  <button type="submit" className="btn btn-primary">
                    Update
                    {/* {edit.editEnable ? "Update About us" : "Create About us"} */}
                  </button>
                </div>
              </div>
              {/* .row // */}
            </div>

            {/* card body .// */}
          </div>
        </form>

        {/* card .// */}
      </section>
    </Layout>
  );
}

export default PrivacyPolicy;
