import React from "react";
import { SidebarMenu } from "../../interfaces/elementInterfaces";
import { useLocation, NavLink } from "react-router-dom";

function SidebarList({ list }: { list: SidebarMenu }) {
  const location = useLocation();
  const [open, setOpen] = React.useState<boolean>(false);
  const active = location.pathname === list.link;

  if (list.hasSubmenu) {
    return (
      <li
        className={`menu-item ${active ? "active" : ""}  ${
          list.hasSubmenu ? "has-submenu" : ""
        }`}
        onClick={() => setOpen(!open)}
      >
        <div className="menu-link ">
          <i className={list.icon} />
          <span className="text">{list.title}</span>
        </div>
        <div className="submenu" style={{ display: open ? "block" : "none" }}>
          {list.children.map((submenu) => {
            return (
              <NavLink key={submenu.title} to={submenu.link}>
                {submenu.title}
              </NavLink>
            );
          })}
        </div>
      </li>
    );
  }

  return (
    <li className={`menu-item ${active ? "active" : ""} `}>
      <NavLink className="menu-link" to={list.link}>
        <i className={list.icon} />
        <span className="text">{list.title}</span>
      </NavLink>
    </li>
  );
}

export default SidebarList;
