import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { useFormik } from "formik";
import { requestHandler, uploadFileHandler } from "../../utils/requestHandler";
import Image from "../../components/elements/Image";
import { IMG_BASE_URL } from "../../utils/constants";
import {
  AboutusCardContentsI,
  AboutusI,
  ClipArtPrintingPrice,
  DesignCategoryI,
  DesignI,
  DesignResponseI,
  PrintingMethodI,
} from "../../interfaces/productInterfaces";
import useGet from "../../hooks/useGet";
import Pagination from "../../components/elements/Pagination";
import Editor from "../../components/elements/Editor";
function AboutUs() {
  const [aboutus, setAboutus] = useState<AboutusI>({
    aboutusTitle: "",
    image: "",
    aboutusDescription: "",
    cardContentTitle: "",
  });
  const [cardContents, setCardContents] = useState<AboutusCardContentsI[]>([
    {
      title: "",
      desc: "",
      img: "",
    },
  ]);

  const [edit, setEdit] = useState({
    editEnable: false,
    editId: "",
  });
  const formik = useFormik({
    initialValues: {
      aboutusTitle: "",
      image: "",
      aboutusDescription: "",
      cardContentTitle: "",
      // isActive: false,
      // isTopselling:false,
      // isEmbroidery:false,
      // isPremium:false,
      // isFontBasedDesign:false
    },
    onSubmit: (values, { resetForm }) => {},
  });

  const deleteImage = () => {
    // formik.setFieldValue("imageUrl", "");
    setAboutus((prev) => ({ ...prev, image: "" }));
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let data = new FormData();
      data.append("file", e.target.files[0]);
      data.append("path", "aboutus/images");

      uploadFileHandler<string>({
        endpoint: "/api/v1/upload",
        body: data,
      })
        .then((response) => {
          if (response.data) {
            setAboutus((prev) => ({
              ...prev,
              image: `${IMG_BASE_URL}/${response.data}`,
            }));
          }
          // formik.setFieldValue("image", );
        })
        .catch((err) => {
          alert(err.message || "Error uploading in image try again!!");
        });

      // console.log(response.data);
    }
  };

  const onContentFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number
  ) => {
    if (e.target.files) {
      let data = new FormData();
      data.append("file", e.target.files[0]);
      data.append("path", "aboutus/images");

      uploadFileHandler<string>({
        endpoint: "/api/v1/upload",
        body: data,
      })
        .then((response) => {
          if (response.data) {
            let updated = [...cardContents];
            updated[i].img = `${IMG_BASE_URL}/${response.data}`;
            setCardContents(updated);
            // setAboutus((prev)=>({...prev,image:`${IMG_BASE_URL}/${response.data}`}))
          }
          // formik.setFieldValue("image", );
        })
        .catch((err) => {
          alert(err.message || "Error uploading in image try again!!");
        });

      // console.log(response.data);
    }
  };

  const handleDelete = async (id: string) => {
    requestHandler({
      endpoint: `/api/v1/designs/${id}`,
      method: "DELETE",
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          getAllDesigns();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const getAllDesigns = useCallback(async () => {
    requestHandler<AboutusI>({
      endpoint: `/api/v1/about-us`,
      method: "GET",
    })
      .then((res) => {
        if (res.success) {
          let data = res.data;
          setAboutus((prev) => ({
            aboutusTitle: data.aboutusTitle,
            aboutusDescription: data.aboutusDescription,
            image: data.image,
            cardContentTitle: data.cardContentTitle,
          }));
          if (res.data.cardContents)
            setCardContents([...res.data.cardContents]);
        }
      })
      .catch((err) => {
        // alert(err.message);
      });
  }, []);

  useEffect(() => {
    getAllDesigns();
  }, [getAllDesigns]);

  const updateStatus = (designId: string, data: any) => {
    requestHandler({
      endpoint: `/api/v1/designs/${designId}`,
      method: "PUT",
      body: data,
    })
      .then((res) => {
        if (res.success) {
          getAllDesigns();
          alert(res.message);
          // resetForm();
          setEdit({ editEnable: false, editId: "" });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    requestHandler({
      endpoint: "/api/v1/about-us",
      method: "PUT",
      body: {
        ...aboutus,
        cardContents,
      },
    })
      .then((res) => {
        if (res.success) {
          getAllDesigns();
          alert(res.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  return (
    <Layout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">About Us</h2>
            <p>Add, edit or delete a About us</p>
          </div>
          {/* <div>
          <input
            type="text"
            placeholder="Search Categories"
            className="form-control bg-white"
          />
        </div> */}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 mb-4">
                  <label htmlFor="aboutusTitle" className="form-label">
                    About-us Title
                  </label>
                  <input
                    type="text"
                    placeholder="Type here"
                    className="form-control"
                    id="aboutusTitle"
                    name="aboutusTitle"
                    value={aboutus.aboutusTitle}
                    onChange={(e) =>
                      setAboutus((prev) => ({
                        ...prev,
                        aboutusTitle: e.target.value,
                      }))
                    }
                    // onChange={formik.handleChange}
                  />
                </div>
                <div className="col-md-12 mb-4">
                  <label htmlFor="aboutusDescription" className="form-label">
                    About us description
                  </label>
                  <Editor
                    value={aboutus.aboutusDescription}
                    onChange={(e, editor) =>
                      setAboutus((prev) => ({
                        ...prev,
                        aboutusDescription: editor.getData(),
                      }))
                    }
                  />
                </div>

                {/* <div className="mb-4">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <textarea
                      placeholder="Type here"
                      className="form-control"
                      id="description"
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                    />
                  </div> */}
                <div className="col-md-6 mb-4">
                  <label htmlFor="image" className="form-label">
                    Image
                  </label>
                  <input
                    type="file"
                    placeholder="Type here"
                    className="form-control"
                    id="image"
                    accept="image/*"
                    onChange={onFileChange}
                  />
                  {aboutus.image && (
                    <Image src={aboutus.image} onDelete={deleteImage} />
                  )}
                </div>
                <div className="col-md-12 mb-4">
                  <label htmlFor="cardContentTitle" className="form-label">
                    Cards Content Title
                  </label>
                  <input
                    type="text"
                    placeholder="Type here"
                    className="form-control"
                    id="cardContentTitle"
                    name="cardContentTitle"
                    value={aboutus.cardContentTitle}
                    onChange={(e) =>
                      setAboutus((prev) => ({
                        ...prev,
                        cardContentTitle: e.target.value,
                      }))
                    }
                  />
                </div>

                {/* .col// */}
              </div>
              <div className="card">
                <div className="card-header " style={{ alignSelf: "flex-end" }}>
                  <button
                    type="button"
                    onClick={() => {
                      let updated = [...cardContents];
                      updated.push({
                        title: "",
                        desc: "",
                        img: "",
                      });
                      setCardContents(updated);
                    }}
                    className="btn btn-primary"
                  >
                    +
                  </button>
                </div>
                <div className="card-body">
                  {cardContents.map((item, i) => {
                    console.log(item);

                    return (
                      <div className="card" key={i}>
                        <div className="card-body">
                          {/* <div className="card-header"> */}
                          <button
                            style={{ float: "right" }}
                            type="button"
                            onClick={() => {
                              let updated = [...cardContents];
                              updated.splice(i, 1);
                              setCardContents(updated);
                            }}
                            className="btn btn-danger"
                          >
                            -
                          </button>
                          {/* </div> */}
                          <div className="row">
                            <div className="col-md-12 mb-4">
                              <label className="form-label">
                                Content Title
                              </label>
                              <input
                                type="text"
                                placeholder="Type here"
                                className="form-control"
                                value={item.title}
                                onChange={(e) => {
                                  let updated = [...cardContents];
                                  updated[i].title = e.target.value;
                                  setCardContents(updated);
                                }}
                              />
                            </div>
                            <div className="col-md-12 mb-4">
                              <label htmlFor="price" className="form-label">
                                Content description
                              </label>
                              <Editor
                                value={item.desc}
                                onChange={(e, editor) => {
                                  let updated = [...cardContents];
                                  updated[i].desc = editor.getData();
                                  setCardContents(updated);
                                }}
                              />
                            </div>

                            <div className="col-md-6 mb-4">
                              <label htmlFor="image" className="form-label">
                                Content Image
                              </label>
                              <input
                                type="file"
                                placeholder="Type here"
                                className="form-control"
                                accept="image/*"
                                onChange={(e) => onContentFileChange(e, i)}
                              />
                              {item.img && (
                                <Image
                                  src={item.img}
                                  onDelete={() => {
                                    let updated = [...cardContents];
                                    updated[i].img = "";
                                    setCardContents(updated);
                                  }}
                                />
                              )}
                            </div>

                            {/* .col// */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="">
                <div>
                  <button type="submit" className="btn btn-primary">
                    Update
                    {/* {edit.editEnable ? "Update About us" : "Create About us"} */}
                  </button>
                </div>
              </div>
              {/* .row // */}
            </div>

            {/* card body .// */}
          </div>
        </form>

        {/* card .// */}
      </section>
    </Layout>
  );
}

export default AboutUs;
