import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { requestHandler } from "../../utils/requestHandler";
import {
  ProductResponse,
  Product,
  SearchedProduct,
} from "../../interfaces/productInterfaces";
import ProductCard from "./ProductCard";
import Pagination from "../../components/elements/Pagination";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useCategory from "../../hooks/useCategory";
function TopSellingProducts() {
  const { categories, fetchCategories, fetchCategoryDetail, categoryDetail } =
    useCategory();
  const navigate = useNavigate();
  const [searchedProduct, setSearchedProduct] = useState<SearchedProduct[]>([]);
  const [productResponse, setProductsResponse] = useState<ProductResponse>({
    products: [],
    totalPages: 0,
    totalProducts: 0,
  });
  const [selectedProduct, setSelectedProduct] = useState<SearchedProduct[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const fetchProducts = useCallback(async () => {
    setLoading(true);
    requestHandler<ProductResponse>({
      endpoint: `/api/v1/products?page=${currentPage}&limit=10&topselling=true`,
      method: "GET",
    })
      .then((res) => {
        if (res.success) {
          setProductsResponse(res.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [currentPage]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const deleteHandle = async (product_id: String) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      let data = {
        productId: product_id,
        isTopSelling: false,
      };
      if (result.isConfirmed) {
        requestHandler({
          endpoint: `/api/v1/products`,
          method: "PUT",
          body: data,
        })
          .then((res) => {
            fetchProducts();
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
          })
          .catch((err) => {
            alert(err.message);
          });
      }
    });
  };

  const addToPopular = (id: string, status: boolean) => {
    requestHandler({
      endpoint: "/api/v1/products",
      method: "PUT",
      body: { isPopular: status, productId: id },
    })
      .then((res) => {
        if (res.success) {
          fetchProducts();
          Swal.fire({
            title: "Added!",
            text: `Your Product ${
              status ? "added to " : "removed from"
            } popular list`,
            icon: "success",
          }); // resetForm();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const searchHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) return setSearchedProduct([]);

    requestHandler<SearchedProduct[]>({
      endpoint: `/api/v1/search?query=${e.target.value}`,
      method: "GET",
    })
      .then((res) => {
        setSearchedProduct(res.data);
      })
      .catch((err) => {
        setSearchedProduct([]);
      });
  };

  const BulkUpdate = () => {
    if (selectedProduct.length === 0) return;

    let data = selectedProduct.map((item) => ({
      productId: item._id,
      data: { isTopSelling: true },
    }));

    requestHandler({
      endpoint: "/api/v1/products/bulk-update",
      method: "PUT",
      body: { products: data },
    })
      .then((res) => {
        alert(res.message);
        setSelectedProduct([]);
        fetchProducts();
      })
      .catch((err) => {
        alert(err);
      });
  };
  return (
    <Layout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">Top Selling Products </h2>
            {/* <p>Lorem ipsum dolor sit amet.</p> */}
          </div>
          <div>
            {/* <button
              className="btn btn-primary btn-sm rounded"
              onClick={() => navigate("/create-product")}
            >
              Create new
            </button> */}
          </div>
        </div>
        <div className="card mb-4" style={{ overflow: "visible" }}>
          {/* <header className="card-header">
            <div className="row gx-3">
              <div className="col-lg-4 col-md-6 me-auto">
                <input
                  type="text"
                  placeholder="Search..."
                  className="form-control"
                />
              </div>
              <div className="col-lg-2 col-6 col-md-3">
                <select className="form-select">
                  <option>All category</option>
                  <option>Electronics</option>
                  <option>Clothings</option>
                  <option>Something else</option>
                </select>
              </div>
              <div className="col-lg-2 col-6 col-md-3">
                <select className="form-select">
                  <option>Latest added</option>
                  <option>Cheap first</option>
                  <option>Most viewed</option>
                </select>
              </div>
            </div>
          </header> */}
          {/* card-header end// */}

          <div className="card-body" style={{ minWidth: "500px" }}>
            <div className="col-12">
              <form className="row">
                {/* <div className="col-md-4 mb-4">
                  <label className="form-label">Categories</label>
                  <select
                    //   value={formik.values.designCategory}
                    name="designCategory"
                    className="form-select"
                    //   onChange={formik.handleChange}
                  >
                    <option value={""}>select</option>
                    {categories.map((item) => {
                      return (
                        <option key={item._id} value={item._id}>
                          {item.categoryName}
                        </option>
                      );
                    })}
                  </select>
                </div> */}
                <div className="col-md-8 mb-4">
                  <label htmlFor="title" className="form-label">
                    Name
                  </label>

                  <input
                    type="text"
                    placeholder="Search Product"
                    className="form-control"
                    id="title"
                    name="title"
                    onChange={searchHandle}

                    //   value={formik.values.title}
                    //   onChange={formik.handleChange}
                  />
                  {searchedProduct.length > 0 && (
                    <div className="search-list">
                      {searchedProduct.map((product) => {
                        return (
                          <div
                            key={product._id}
                            className="search-product"
                            onClick={() => {
                              setSelectedProduct((prev) => [...prev, product]);
                              setSearchedProduct([]);
                            }}
                          >
                            <img
                              src={product.image as string}
                              alt={product.name}
                            />{" "}
                            {product.name}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>

                <div className="col-md-2 d-flex align-items-center text-center">
                  <div>
                    <button
                      type="button"
                      className="btn btn-xs"
                      onClick={BulkUpdate}
                    >
                      Add To Top Selling
                      {/* {edit.editEnable ? "Update Designs" : "Create Designs"} */}
                    </button>
                  </div>
                </div>
              </form>
              <div>
                {selectedProduct.map((item, i) => {
                  return (
                    <button
                      className="btn btn-primary btn-sm rounded mx-2 my-1 "
                      key={item._id}
                      onClick={() => {
                        let updated = [...selectedProduct];
                        updated.splice(i, 1);
                        setSelectedProduct(updated);
                      }}
                    >
                      {item.name}
                      <i
                        className="icon material-icons md-close "
                        style={{ marginLeft: "10px" }}
                      />{" "}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="row gx-3 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 row-cols-xxl-5">
              {productResponse.products.map((product) => {
                return (
                  <ProductCard
                    key={product._id}
                    product={product}
                    onDelete={deleteHandle}
                    addToPopular={(id) =>
                      addToPopular(id, !Boolean(product.isPopular))
                    }
                  />
                );
              })}

              {productResponse.products.length === 0 && (
                <p>No Products found</p>
              )}

              {/* col.// */}
            </div>
            {/* row.// */}
          </div>
          {/* card-body end// */}
        </div>
        {/* card end// */}
        {/* <Pagination
          currentPage={currentPage}
          totalPages={productResponse.totalPages}
          onPageChange={handlePageChange}
        /> */}
      </section>
    </Layout>
  );
}

export default TopSellingProducts;
