import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  EventInfo,
} from "ckeditor5";

import "ckeditor5/ckeditor5.css";
import { useEffect, useRef } from "react";

type OnChangeHandler = (
  event: EventInfo<string, unknown>,
  editor: ClassicEditor
) => void;

function Editor({
  onChange,
  value,
}: {
  onChange: OnChangeHandler;
  value: string;
}) {
  const editorRef = useRef<ClassicEditor | null>(null);
  const isInitialMount = useRef(true);

  useEffect(() => {
    isInitialMount.current = true;
  }, [value]);

  return (
    <CKEditor
      editor={ClassicEditor}
      config={{
        toolbar: {
          items: ["undo", "redo", "|", "bold", "italic"],
        },
        plugins: [Bold, Essentials, Italic, Mention, Paragraph, Undo],
      }}
      onReady={(editor) => {
        editorRef.current = editor;
      }}
      onChange={(event, editor) => {
        if (isInitialMount.current) {
          // Skip the first onChange triggered by the value prop update
          isInitialMount.current = false;
        } else {
          // Only call onChange when the user types or interacts with the editor
          onChange(event, editor);
        }
      }}
      data={value}
    />
  );
}

export default Editor;
