// src/utils/requestHandler.ts
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { BASE_URL } from "./constants";

type RequestType = "GET" | "POST" | "PUT" | "DELETE";

interface RequestOptions<TBody = any> {
  endpoint: string;
  method: RequestType;
  body?: TBody;
  headers?: HeadersInit;
  contentType?: string;
}
interface UploadRequestOptions<TBody = any> {
  endpoint: string; // The endpoint on your API to upload the file to
  method?: "POST" | "PUT" | string; // Optional HTTP method (defaults to POST)
  body: TBody; // The File object representing the file to upload
  headers?: Record<string, string>; // Optional additional headers for the request
  onProgress?: (progress: number) => void; // Optional callback for upload progress updates
}

export interface ApiResponse<T> {
  data: T;
  message: string;
  success: boolean;
  statusCode: number;
}
export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "http://143.110.185.221:5000",
  },
  withCredentials:true
});

export async function requestHandler<TResponse>({
  endpoint,
  method,
  body,
  headers = {},
  contentType = "application/json",
}: RequestOptions): Promise<ApiResponse<TResponse>> {
  // const baseUrl = "http://143.110.185.221:5000"; // Change this to your API's base URL
  // const baseUrl = "http://localhost:5000";
  // const Allow_Origin = "http://143.110.185.221:5000";
  // const url = `${baseUrl}${endpoint}`;

  const config: AxiosRequestConfig = {
    method,
    url:endpoint,
  };

  if (body && contentType === "application/json") {
    config.data = JSON.stringify(body);
  } else if (body) {
    config.data = body;
  }

  try {
    const response: AxiosResponse<TResponse> = await api(config);
    return response.data as ApiResponse<TResponse>;
  } catch (error: any) {
    if (error.response) {
      throw new Error(
        error.response.data.message ||
          "API call failed without a specified error"
      );
    } else {
      throw new Error(`API call failed: ${error.message}`);
    }
  }
}
export async function uploadFileHandler<TResponse>({
  endpoint,
  method = "POST", // Default to POST for file uploads
  body,
  headers = {},
  onProgress = (progress) => {}, // Optional progress callback
}: UploadRequestOptions): Promise<ApiResponse<TResponse>> {
  const baseUrl = BASE_URL; // Replace with your API base URL

  const url = `${baseUrl}${endpoint}`;

  // Validate file for type, size, etc. (optional)
  // if (!isValidFile(file)) {
  //   throw new Error("Invalid file type or size");
  // }

  // const formData = new FormData(); // Use FormData for file uploads
  // formData.append("file", file);

  // Add other form data fields if needed
  // formData.append("fieldName", fieldValue);

  const config: RequestInit = {
    method,
    headers: {
      ...headers,
    },
    body: body,
  };

  try {
    const response = await fetch(url, config);
    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        errorResponse.message || "API call failed without a specified error"
      );
    }
    return (await response.json()) as ApiResponse<TResponse>;
  } catch (error) {
    throw new Error(`API call failed: ${error}`);
  }
}

// export const api = axios.create({
//   baseURL:"http://localhost:5000"
// })
