import React from 'react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const getPageNumbers = () => {
    const pages = [];
    const maxPageNumbers = 5; // Maximum page numbers to show
    let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
    let endPage = startPage + maxPageNumbers - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxPageNumbers + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className="pagination-area mt-30 mb-50">
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-start">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); if (currentPage > 1) onPageChange(currentPage - 1); }}>
              <i className="material-icons md-chevron_left" />
            </a>
          </li>
          {pageNumbers.map(number => (
            <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
              <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); onPageChange(number); }}>
                {number < 10 ? `0${number}` : number}
              </a>
            </li>
          ))}
          {totalPages > pageNumbers[pageNumbers.length - 1] && (
            <li className="page-item">
              <a className="page-link dot" href="#" onClick={(e) => e.preventDefault()}>
                ...
              </a>
            </li>
          )}
          {totalPages > pageNumbers[pageNumbers.length - 1] && (
            <li className={`page-item ${currentPage === totalPages ? 'active' : ''}`}>
              <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); onPageChange(totalPages); }}>
                {totalPages < 10 ? `0${totalPages}` : totalPages}
              </a>
            </li>
          )}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); if (currentPage < totalPages) onPageChange(currentPage + 1); }}>
              <i className="material-icons md-chevron_right" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
