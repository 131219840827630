import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { useFormik } from "formik";
import { requestHandler, uploadFileHandler } from "../../utils/requestHandler";
import Image from "../../components/elements/Image";
import { IMG_BASE_URL } from "../../utils/constants";
import {
  ClipArtPrintingPrice,
  DesignCategoryI,
  DesignI,
  DesignResponseI,
  PrintingMethodI,
} from "../../interfaces/productInterfaces";
import useGet from "../../hooks/useGet";
import Pagination from "../../components/elements/Pagination";
function Designs() {
  const printingMethods = useGet<PrintingMethodI>("/api/v1/printing-method");
  const { data } = useGet<DesignCategoryI>("/api/v1/design-category");

  const [designs, setDesigns] = useState<DesignResponseI>({
    designs: [],
    totalDesigns: 0,
    totalPages: 0,
  });
  const [printingPrices, setPrintingPrices] = useState<ClipArtPrintingPrice[]>(
    []
  );

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [query, setQuery] = useState<any>({});

  const [edit, setEdit] = useState({
    editEnable: false,
    editId: "",
  });
  const formik = useFormik({
    initialValues: {
      designCategory: "",
      title: "",
      imageUrl: "",
      price: 0,
      // isActive: false,
      // isTopselling:false,
      // isEmbroidery:false,
      // isPremium:false,
      // isFontBasedDesign:false
    },
    onSubmit: (values, { resetForm }) => {
      if (!edit.editEnable) {
        requestHandler({
          endpoint: "/api/v1/designs",
          method: "POST",
          body: {
            ...values,
            printingPrice: printingPrices.map((item) => ({
              printingMethod: item.printingMethod._id,
              price: item.price,
            })),
          },
        })
          .then((res) => {
            if (res.success) {
              getAllDesigns();
              alert(res.message);
              resetForm();
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      } else {
        requestHandler({
          endpoint: `/api/v1/designs/${edit.editId}`,
          method: "PUT",
          body: {
            ...values,
            printingPrice: printingPrices.map((item) => ({
              printingMethod: item.printingMethod._id,
              price: item.price,
            })),
          },
        })
          .then((res) => {
            if (res.success) {
              getAllDesigns();
              alert(res.message);
              resetForm();
              setEdit({ editEnable: false, editId: "" });
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      }
    },
  });

  const deleteImage = () => {
    formik.setFieldValue("imageUrl", "");
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let data = new FormData();
      data.append("file", e.target.files[0]);
      data.append("path", "designs/images");

      uploadFileHandler<string>({
        endpoint: "/api/v1/upload",
        body: data,
      })
        .then((response) => {
          if (response.data)
            formik.setFieldValue(
              "imageUrl",
              `${IMG_BASE_URL}/${response.data}`
            );
        })
        .catch((err) => {
          alert(err.message || "Error uploading in image try again!!");
        });

      // console.log(response.data);
    }
  };

  const handleDelete = async (id: string) => {
    requestHandler({
      endpoint: `/api/v1/designs/${id}`,
      method: "DELETE",
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          getAllDesigns();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const getAllDesigns = useCallback(async () => {
    const queryString = new URLSearchParams(query).toString();

    requestHandler<DesignResponseI>({
      endpoint: `/api/v1/designs?page=${currentPage}&limit=10&${queryString}`,
      method: "GET",
    })
      .then((res) => {
        if (res.success) {
          setDesigns(res.data);
        }
      })
      .catch((err) => {
        // alert(err.message);
      });
  }, [query, currentPage]);

  useEffect(() => {
    getAllDesigns();
  }, [getAllDesigns]);

  const updateStatus = (designId: string, data: any) => {
    requestHandler({
      endpoint: `/api/v1/designs/${designId}`,
      method: "PUT",
      body: data,
    })
      .then((res) => {
        if (res.success) {
          getAllDesigns();
          alert(res.message);
          // resetForm();
          setEdit({ editEnable: false, editId: "" });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    let printingPricesTabele = printingMethods.data.map((item) => ({
      printingMethod: item,
      price: 0,
    }));

    setPrintingPrices(printingPricesTabele);
  }, [printingMethods.data]);
  return (
    <Layout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">Designs</h2>
            <p>Add, edit or delete a Designs</p>
          </div>
          {/* <div>
          <input
            type="text"
            placeholder="Search Categories"
            className="form-control bg-white"
          />
        </div> */}
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <form onSubmit={formik.handleSubmit} className="row">
                  <div className="col-md-6 mb-4">
                    <label className="form-label">Deisgn Category</label>
                    <select
                      value={formik.values.designCategory}
                      name="designCategory"
                      className="form-select"
                      onChange={formik.handleChange}
                    >
                      <option value={""}>select</option>
                      {data.map((item) => {
                        return (
                          <option key={item._id} value={item._id}>
                            {item.title}
                          </option>
                        );
                      })}
                      {/* <option value="fixed">Fixed</option> */}
                    </select>
                  </div>
                  <div className="col-md-6 mb-4">
                    <label htmlFor="title" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      id="title"
                      name="title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="col-md-6 mb-4">
                    <label htmlFor="price" className="form-label">
                      Price
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className="form-control"
                      id="price"
                      name="price"
                      value={formik.values.price}
                      onChange={formik.handleChange}
                    />
                  </div>

                  {/* <div className="mb-4">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <textarea
                      placeholder="Type here"
                      className="form-control"
                      id="description"
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                    />
                  </div> */}
                  <div className="col-md-6 mb-4">
                    <label htmlFor="image" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      placeholder="Type here"
                      className="form-control"
                      id="image"
                      accept="image/*"
                      onChange={onFileChange}
                    />
                    {formik.values.imageUrl && (
                      <Image
                        src={formik.values.imageUrl}
                        onDelete={deleteImage}
                      />
                    )}
                  </div>

                  {printingPrices.map((item, i) => {
                    return (
                      <React.Fragment key={i}>
                        <div className="col-md-6 mb-4">
                          <label htmlFor="price" className="form-label">
                            Printing Method
                          </label>
                          <input
                            type="text"
                            disabled={true}
                            placeholder="Type here"
                            className="form-control"
                            value={item.printingMethod.methodName}

                            // value={formik.values.price}
                            // onChange={formik.handleChange}
                          />
                        </div>
                        <div className="col-md-6 mb-4" key={i}>
                          <label htmlFor="price" className="form-label">
                            Printing Price
                          </label>
                          <input
                            type="number"
                            placeholder="Type here"
                            className="form-control"
                            value={item.price}
                            min={"0"}
                            onChange={(e) => {
                              let updated = [...printingPrices];
                              updated[i].price = parseInt(e.target.value);
                              setPrintingPrices(updated);
                            }}

                            // value={formik.values.price}
                            // onChange={formik.handleChange}
                          />
                        </div>
                      </React.Fragment>
                    );
                  })}

                  <div className="">
                    <div>
                      <button type="submit" className="btn btn-primary">
                        {edit.editEnable ? "Update Designs" : "Create Designs"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-12 mt-2">
                <header className="card-header">
                  <div className="row gx-3">
                    <div className="col-lg-4 col-md-6 me-auto">
                      <input
                        type="text"
                        placeholder="Search..."
                        className="form-control"
                      />
                    </div>
                    {/* <div className="col-lg-2 col-6 col-md-3">
                      <select className="form-select">
                        <option>All category</option>
                        <option>Electronics</option>
                        <option>Clothings</option>
                        <option>Something else</option>
                      </select>
                    </div> */}
                    <div className="col-lg-4 col-6 col-md-3">
                      <select
                        className="form-select"
                        onChange={(e) => {
                          if (!e.target.value) setQuery({});
                          setQuery({ designCategory: e.target.value });
                        }}
                      >
                        <option value="">Latest added</option>
                        {data.map((item) => {
                          return (
                            <option value={item._id} key={item._id}>
                              {item.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </header>
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                            />
                          </div>
                        </th>
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Image</th>
                        <th>Base Price</th>
                        <th>Printing Price</th>

                        <th>Is Top selling</th>
                        <th>Is Embroidery</th>
                        <th>Is Premium</th>
                        <th>Is Font Based</th>
                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {designs.designs.map((banner, i) => {
                        return (
                          <tr key={banner._id}>
                            <td className="text-center">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                />
                              </div>
                            </td>
                            <td>{i + 1}</td>
                            <td>{banner.title}</td>

                            <td>
                              <Image deleteShow={false} src={banner.imageUrl} />
                            </td>
                            <td>{banner.price}</td>
                            <td>{banner.printingPrice?.map((item)=><li key={item.printingMethod._id}>{item.printingMethod.methodName} : {item.price}</li>)}</td>

                            <td>
                              {banner.isTopselling ? (
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    updateStatus(banner._id, {
                                      isTopselling: !banner.isTopselling,
                                    })
                                  }
                                >
                                  Yes
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary"
                                  style={{ background: "red" }}
                                  onClick={() =>
                                    updateStatus(banner._id, {
                                      isTopselling: !banner.isTopselling,
                                    })
                                  }
                                >
                                  No
                                </button>
                              )}
                            </td>
                            <td>
                              {banner.isEmbroidery ? (
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    updateStatus(banner._id, {
                                      isEmbroidery: !banner.isEmbroidery,
                                    })
                                  }
                                >
                                  Yes
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary"
                                  style={{ background: "red" }}
                                  onClick={() =>
                                    updateStatus(banner._id, {
                                      isEmbroidery: !banner.isEmbroidery,
                                    })
                                  }
                                >
                                  No
                                </button>
                              )}
                            </td>
                            <td>
                              {banner.isPremium ? (
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    updateStatus(banner._id, {
                                      isPremium: !banner.isPremium,
                                    })
                                  }
                                >
                                  Yes
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    updateStatus(banner._id, {
                                      isPremium: !banner.isPremium,
                                    })
                                  }
                                  style={{ background: "red" }}
                                >
                                  {" "}
                                  No
                                </button>
                              )}
                            </td>
                            <td>
                              {banner.isFontBasedDesign ? (
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    updateStatus(banner._id, {
                                      isFontBasedDesign:
                                        !banner.isFontBasedDesign,
                                    })
                                  }
                                >
                                  Yes
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    updateStatus(banner._id, {
                                      isFontBasedDesign:
                                        !banner.isFontBasedDesign,
                                    })
                                  }
                                  style={{ background: "red" }}
                                >
                                  No
                                </button>
                              )}
                            </td>

                            <td className="text-end">
                              <div className="dropdown">
                                <a
                                  href="#"
                                  data-bs-toggle="dropdown"
                                  className="btn btn-light rounded btn-sm font-sm"
                                >
                                  {" "}
                                  <i className="material-icons md-more_horiz" />{" "}
                                </a>
                                <div className="dropdown-menu">
                                  {/* <div
                                  className="dropdown-item"
                                  onClick={() =>
                                    viewSubCategory(category.slug)
                                  }
                                >
                                  View/Add Subcategories
                                </div> */}
                                  <div
                                    className="dropdown-item"
                                    onClick={() => {
                                      formik.setFieldValue(
                                        "title",
                                        banner.title
                                      );
                                      formik.setFieldValue(
                                        "imageUrl",
                                        banner.imageUrl
                                      );
                                      formik.setFieldValue(
                                        "price",
                                        banner.price
                                      );
                                      formik.setFieldValue("designCategory",banner.designCategory._id)
                                      setEdit((prev) => ({
                                        ...prev,
                                        editEnable: true,
                                        editId: banner._id,
                                      }));
                                      let updated = printingPrices.map(
                                        (item) => {
                                          return {
                                            ...item,
                                            price:
                                              banner.printingPrice?.find(
                                                (print) =>
                                                  print.printingMethod._id ===
                                                  item.printingMethod._id
                                              )?.price || 0,
                                          };
                                        }
                                      );

                                      setPrintingPrices(updated);
                                      window.scrollTo({
                                        top:0,
                                        left:0,
                                        behavior:"smooth"
                                      })
                                      // setPrintingPrices((item)=>({p}))
                                    }}
                                  >
                                    Edit info
                                  </div>
                                  <div
                                    className="dropdown-item text-danger"
                                    onClick={() => handleDelete(banner._id)}
                                  >
                                    Delete
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* .col// */}
            </div>
            {/* .row // */}
          </div>
          {/* card body .// */}
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={designs.totalPages}
          onPageChange={handlePageChange}
        />
        {/* card .// */}
      </section>
    </Layout>
  );
}

export default Designs;
