import React, { useEffect, useState } from "react";
import { requestHandler } from "../../utils/requestHandler";
import { useAuth } from "../../context/AuthProvider";
import { AdminDetail } from "../../interfaces/userInterfaces";
import { useNavigate } from "react-router-dom";
import storage from "../../utils/storage";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setToken, token } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    requestHandler<AdminDetail>({
      endpoint: "/api/v1/auth/admin/login",
      method: "POST",
      body: {
        email,
        password,
      },
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          setToken(res.data.accessToken);
          storage.set("auth",res.data.refreshToken)
          navigate("/");
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token]);
  return (
    <section className="content-main mt-80 mb-80">
      <div className="card mx-auto card-login">
        <div className="card-body">
          <h4 className="card-title mb-4">Sign in</h4>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <input
                className="form-control"
                placeholder="Username or email"
                type="text"
                value={email}
                onChange={(val) => setEmail(val.target.value)}
              />
            </div>
            {/* form-group// */}
            <div className="mb-3">
              <input
                className="form-control"
                placeholder="Password"
                type="password"
                value={password}
                onChange={(val) => setPassword(val.target.value)}
              />
            </div>
            {/* form-group// */}
            <div className="mb-3">
              <a href="#" className="float-end font-sm text-muted">
                Forgot password?
              </a>
              <label className="form-check">
                <input type="checkbox" className="form-check-input" />
                <span className="form-check-label">Remember</span>
              </label>
            </div>
            {/* form-group form-check .// */}
            <div className="mb-4">
              <button type="submit" className="btn btn-primary w-100">
                Login
              </button>
            </div>
            {/* form-group// */}
          </form>
        </div>
      </div>
    </section>
  );
}

export default Login;
