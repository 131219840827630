import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";

function Layout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <div className="screen-overlay"></div>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        {children}
      </main>
    </>
  );
}

export default Layout;
