import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { useFormik } from "formik";
import { requestHandler, uploadFileHandler } from "../../utils/requestHandler";
import Image from "../../components/elements/Image";
import { IMG_BASE_URL } from "../../utils/constants";
import {
  CouponI,
  Review,
  ReviewResponseI,
} from "../../interfaces/productInterfaces";
import { dateFormat } from "../../utils/convert";
import Pagination from "../../components/elements/Pagination";
function Reviews() {
  const [reviews, setReviews] = useState<ReviewResponseI>({
    reviews: [],
    totalPages: 0,
    totalReviews: 0,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleDelete = async (id: string, status: boolean) => {
    requestHandler({
      endpoint: `/api/v1/reviews/${id}`,
      method: "PUT",
      body: { isDeleted: !status },
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          getAllReviews();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const getAllReviews = useCallback(async () => {
    requestHandler<ReviewResponseI>({
      endpoint: `/api/v1/reviews?page=${currentPage}&limit=10`,
      method: "GET",
    })
      .then((res) => {
        if (res.success) {
          setReviews(res.data);
          //   setReviews(res.data);
        }
      })
      .catch((err) => {
        // alert(err.message);
      });
  }, [currentPage]);

  useEffect(() => {
    getAllReviews();
  }, [getAllReviews]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  return (
    <Layout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">Reviews</h2>
            {/* <p>Add, edit or delete a Reviews</p> */}
          </div>
          {/* <div>
            <input
              type="text"
              placeholder="Search Categories"
              className="form-control bg-white"
            />
          </div> */}
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                            />
                          </div>
                        </th>
                        <th>S.No</th>
                        <th>Product</th>
                        {/* <th>Description</th> */}

                        <th>Rating</th>
                        <th>Review</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reviews.reviews.map((coupon, i) => {
                        return (
                          <tr key={coupon._id}>
                            <td className="text-center">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                />
                              </div>
                            </td>
                            <td>{i + 1}</td>
                            <td>{coupon.product.name}</td>
                            <td>{coupon.rating}</td>
                            <td style={{ maxWidth: "300px" }}>
                              {coupon.review}
                            </td>
                            <td>{coupon.name}</td>
                            <td>{coupon.email}</td>
                            <td>
                              {coupon.isDeleted ? (
                                <span style={{ color: "red" }}>Deleted</span>
                              ) : (
                                <span style={{ color: "green" }}>Visible</span>
                              )}
                            </td>

                            {/* <td>{coupon.discountType}</td>
                            <td>{coupon.discountValue}</td>
                            <td>{coupon.usageLimit}</td>
                            <td>{coupon.minPurchaseAmount}</td> */}
                            <td className="text-end">
                              <div className="dropdown">
                                <a
                                  href="#"
                                  data-bs-toggle="dropdown"
                                  className="btn btn-light rounded btn-sm font-sm"
                                >
                                  {" "}
                                  <i className="material-icons md-more_horiz" />{" "}
                                </a>
                                <div className="dropdown-menu">
                                  {/* <div
                                    className="dropdown-item"
                                    onClick={() =>
                                      viewSubCategory(category.slug)
                                    }
                                  >
                                    View/Add Subcategories
                                  </div> */}
                                  <div
                                    className={`dropdown-item ${
                                      coupon.isDeleted
                                        ? "text-success"
                                        : "text-danger"
                                    } `}
                                    onClick={() =>
                                      handleDelete(coupon._id, coupon.isDeleted)
                                    }
                                  >
                                    {coupon.isDeleted ? "Visble" : "Delete"}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* .col// */}
            </div>
            {/* .row // */}
          </div>
          {/* card body .// */}
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={reviews.totalPages}
          onPageChange={handlePageChange}
        />
        {/* card .// */}
      </section>
    </Layout>
  );
}

export default Reviews;
