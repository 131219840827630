import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { useFormik } from "formik";
import { requestHandler, uploadFileHandler } from "../../utils/requestHandler";
import Image from "../../components/elements/Image";
import { IMG_BASE_URL } from "../../utils/constants";
import {
  BlogResponseI,
  ClipArtPrintingPrice,
  DesignCategoryI,
  DesignI,
  DesignResponseI,
  PrintingMethodI,
} from "../../interfaces/productInterfaces";
import useGet from "../../hooks/useGet";
import Pagination from "../../components/elements/Pagination";
import Editor from "../../components/elements/Editor";
function Blogs() {
  const [designs, setDesigns] = useState<BlogResponseI>({
    blogs: [],
    totalBlogs: 0,
    totalPages: 0,
  });

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [query, setQuery] = useState<any>({});

  const [edit, setEdit] = useState({
    editEnable: false,
    editId: "",
  });
  const formik = useFormik({
    initialValues: {
      title: "",
      img: "",
      desc: "",
      category: "",
      // isActive: false,
      // isTopselling:false,
      // isEmbroidery:false,
      // isPremium:false,
      // isFontBasedDesign:false
    },
    onSubmit: (values, { resetForm }) => {
      if (!edit.editEnable) {
        requestHandler({
          endpoint: "/api/v1/blogs",
          method: "POST",
          body: {
            ...values,
          },
        })
          .then((res) => {
            if (res.success) {
              getAllDesigns();
              alert(res.message);
              resetForm();
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      } else {
        requestHandler({
          endpoint: `/api/v1/blogs/${edit.editId}`,
          method: "PUT",
          body: {
            ...values,
          },
        })
          .then((res) => {
            if (res.success) {
              getAllDesigns();
              alert(res.message);
              resetForm();
              setEdit({ editEnable: false, editId: "" });
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      }
    },
  });

  const deleteImage = () => {
    formik.setFieldValue("img", "");
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let data = new FormData();
      data.append("file", e.target.files[0]);
      data.append("path", "blogs/images");

      uploadFileHandler<string>({
        endpoint: "/api/v1/upload",
        body: data,
      })
        .then((response) => {
          if (response.data)
            formik.setFieldValue("img", `${IMG_BASE_URL}/${response.data}`);
        })
        .catch((err) => {
          alert(err.message || "Error uploading in image try again!!");
        });

      // console.log(response.data);
    }
  };

  const handleDelete = async (id: string) => {
    requestHandler({
      endpoint: `/api/v1/blogs/${id}`,
      method: "DELETE",
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          getAllDesigns();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const getAllDesigns = useCallback(async () => {
    const queryString = new URLSearchParams(query).toString();

    requestHandler<BlogResponseI>({
      endpoint: `/api/v1/blogs?page=${currentPage}&limit=10`,
      method: "GET",
    })
      .then((res) => {
        if (res.success) {
          setDesigns(res.data);
        }
      })
      .catch((err) => {
        // alert(err.message);
      });
  }, [query, currentPage]);

  useEffect(() => {
    getAllDesigns();
  }, [getAllDesigns]);

  const updateStatus = (designId: string, data: any) => {
    requestHandler({
      endpoint: `/api/v1/designs/${designId}`,
      method: "PUT",
      body: data,
    })
      .then((res) => {
        if (res.success) {
          getAllDesigns();
          alert(res.message);
          // resetForm();
          setEdit({ editEnable: false, editId: "" });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Layout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">Blogs</h2>
            <p>Add, edit or delete a Blogs</p>
          </div>
          {/* <div>
          <input
            type="text"
            placeholder="Search Categories"
            className="form-control bg-white"
          />
        </div> */}
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <form onSubmit={formik.handleSubmit} className="row">
                  <div className="col-md-6 mb-4">
                    <label htmlFor="title" className="form-label">
                      Title
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      id="title"
                      name="title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="col-md-6 mb-4">
                    <label htmlFor="category" className="form-label">
                      Category
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      id="category"
                      name="category"
                      value={formik.values.category}
                      onChange={formik.handleChange}
                    />
                  </div>

                  {/* <div className="mb-4">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <textarea
                      placeholder="Type here"
                      className="form-control"
                      id="description"
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                    />
                  </div> */}
                  <div className="col-md-6 mb-4">
                    <label htmlFor="image" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      placeholder="Type here"
                      className="form-control"
                      id="image"
                      accept="image/*"
                      onChange={onFileChange}
                    />
                    {formik.values.img && (
                      <Image src={formik.values.img} onDelete={deleteImage} />
                    )}
                  </div>
                  <div className="col-md-12 mb-4">
                    <label className="form-label"> description</label>
                    <Editor
                      value={formik.values.desc}
                      onChange={(e, editor) => {
                        formik.setFieldValue("desc", editor.getData());
                      }}
                    />
                  </div>

                  <div className="">
                    <div>
                      <button type="submit" className="btn btn-primary">
                        {edit.editEnable ? "Update Blog" : "Create Blog"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-12 mt-2">
                <header className="card-header">
                  <div className="row gx-3">
                    <div className="col-lg-4 col-md-6 me-auto">
                      <input
                        type="text"
                        placeholder="Search..."
                        className="form-control"
                      />
                    </div>
                    {/* <div className="col-lg-2 col-6 col-md-3">
                      <select className="form-select">
                        <option>All category</option>
                        <option>Electronics</option>
                        <option>Clothings</option>
                        <option>Something else</option>
                      </select>
                    </div> */}
                  </div>
                </header>
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                            />
                          </div>
                        </th>
                        <th>S.No</th>
                        <th>Title</th>
                        <th>Category</th>
                        <th>Image</th>
                        <th>Description</th>
                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {designs.blogs.map((banner, i) => {
                        return (
                          <tr key={banner._id}>
                            <td className="text-center">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                />
                              </div>
                            </td>
                            <td>{i + 1}</td>
                            <td>{banner.title}</td>
                            <td>{banner.category}</td>
                            <td>
                              <Image deleteShow={false} src={banner.img} />
                            </td>
                            <td>{banner.desc.slice(0,100)}...</td>
                            {/* <td>{banner.price}</td> */}
                            {/* <td>{banner.printingPrice?.map((item)=><li key={item.printingMethod._id}>{item.printingMethod.methodName} : {item.price}</li>)}</td> */}

                            <td className="text-end">
                              <div className="dropdown">
                                <a
                                  href="#"
                                  data-bs-toggle="dropdown"
                                  className="btn btn-light rounded btn-sm font-sm"
                                >
                                  {" "}
                                  <i className="material-icons md-more_horiz" />{" "}
                                </a>
                                <div className="dropdown-menu">
                                  {/* <div
                                  className="dropdown-item"
                                  onClick={() =>
                                    viewSubCategory(category.slug)
                                  }
                                >
                                  View/Add Subcategories
                                </div> */}
                                  <div
                                    className="dropdown-item"
                                    onClick={() => {
                                      formik.setFieldValue(
                                        "title",
                                        banner.title
                                      );
                                      formik.setFieldValue("img", banner.img);
                                      formik.setFieldValue(
                                        "category",
                                        banner.category
                                      );

                                      formik.setFieldValue("desc", banner.desc);
                                      setEdit((prev) => ({
                                        ...prev,
                                        editEnable: true,
                                        editId: banner._id,
                                      }));

                                      window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: "smooth",
                                      });
                                      // setPrintingPrices((item)=>({p}))
                                    }}
                                  >
                                    Edit info
                                  </div>
                                  <div
                                    className="dropdown-item text-danger"
                                    onClick={() => handleDelete(banner._id)}
                                  >
                                    Delete
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* .col// */}
            </div>
            {/* .row // */}
          </div>
          {/* card body .// */}
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={designs.totalPages}
          onPageChange={handlePageChange}
        />
        {/* card .// */}
      </section>
    </Layout>
  );
}

export default Blogs;
