import { useCallback, useEffect, useState } from "react";
import { requestHandler } from "../utils/requestHandler";
import { Categories } from "../interfaces/productInterfaces";

function useCategory(effect: boolean | undefined = true) {
  const [categories, setCategories] = useState<Categories[]>([]);
  const [categoryDetail, setCategoryDetail] = useState<Categories | null>(null);

  const fetchCategories = useCallback(async () => {
    requestHandler<Categories[]>({
      endpoint: "/api/v1/category",
      method: "GET",
    })
      .then((res) => {
        if (res.success) {
          setCategories(res.data);
        }
      })
      .catch((err) => {});
  }, []);

  const fetchCategoryDetail = useCallback((data: any) => {
    requestHandler<Categories>({
      endpoint: `/api/v1/category/category-detail`,
      method: "POST",
      body: data,
    })
      .then((res) => {
        if (res.success) {
          setCategoryDetail(res.data);
          setCategories(res.data.subcategories);
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (effect) fetchCategories();
  }, [fetchCategories, effect]);
  return { categories, fetchCategories, fetchCategoryDetail, categoryDetail };
}

export default useCategory;
