import React, { useCallback, useEffect, useState } from "react";
import { requestHandler } from "../utils/requestHandler";

function useGet<T>(endpoint: string) {
  const [data, setData] = useState<T[]>([]);

  const fetchData = useCallback(async () => {
    requestHandler<T[]>({
      endpoint: endpoint,
      method: "GET",
    })
      .then((res) => {
        if (res.success) {
          setData(res.data);
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data };
}

export default useGet;
