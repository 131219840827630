import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { type } from "os";
import { requestHandler } from "../../utils/requestHandler";
import { Order, OrderResponse } from "../../interfaces/productInterfaces";
import { dateFormat } from "../../utils/convert";
import Pagination from "../../components/elements/Pagination";
import { Link } from "react-router-dom";

function Orders() {
  const [orders, setOrders] = useState<OrderResponse>({
    orders: [],
    totalPages: 0,
    totalOrders: 0,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);

  const getAllOrders = useCallback(() => {
    requestHandler<OrderResponse>({
      endpoint: `/api/v1/order/getAllOrders?page=${currentPage}&limit=10`,
      method: "GET",
    })
      .then((res) => {
        if (res.success) {
          setOrders(res.data);
          // if (type === "mask") setMaskedImages(updatedImages);
          // resetForm();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }, []);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getAllOrders();
  }, [getAllOrders]);
  return (
    <Layout>
      <div className="card mb-4">
        <header className="card-header">
          <h4 className="card-title">Latest orders</h4>
          {/* <div className="row align-items-center">
            <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
              <div className="custom_select">
                <select className="form-select select-nice">
                  <option>All Categories</option>
                  <option>Women's Clothing</option>
                  <option>Men's Clothing</option>
                  <option>Cellphones</option>
                  <option>Computer &amp; Office</option>
                  <option>Consumer Electronics</option>
                  <option>Jewelry &amp; Accessories</option>
                  <option>Home &amp; Garden</option>
                  <option>Luggage &amp; Bags</option>
                  <option>Shoes</option>
                  <option>Mother &amp; Kids</option>
                </select>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <input
                type="date"
                defaultValue="02.05.2021"
                className="form-control"
              />
            </div>
            <div className="col-md-2 col-6">
              <div className="custom_select">
                <select className="form-select select-nice">
                  <option>Status</option>
                  <option>All</option>
                  <option>Paid</option>
                  <option>Chargeback</option>
                  <option>Refund</option>
                </select>
              </div>
            </div>
          </div> */}
        </header>
        <div className="card-body">
          <div className="table-responsive">
            <div className="table-responsive">
              <table className="table align-middle table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col" className="text-center">
                      <div className="form-check align-middle">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="transactionCheck01"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="transactionCheck01"
                        />
                      </div>
                    </th>
                    <th className="align-middle" scope="col">
                      Order ID
                    </th>
                    <th className="align-middle" scope="col">
                      Billing Name
                    </th>
                    <th className="align-middle" scope="col">
                      Date
                    </th>
                    <th className="align-middle" scope="col">
                      Total
                    </th>
                    <th className="align-middle" scope="col">
                      Payment Status
                    </th>
                    <th className="align-middle" scope="col">
                      Payment Method
                    </th>
                    <th className="align-middle" scope="col">
                      View Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orders.orders.map((item) => {
                    return (
                      <tr key={item.orderId}>
                        <td className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="transactionCheck02"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="transactionCheck02"
                            />
                          </div>
                        </td>
                        <td>
                          <a href="/" className="fw-bold">
                            #{item.orderId}
                          </a>
                        </td>
                        <td>{item.name}</td>
                        <td>{dateFormat(item.createdAt)}</td>
                        <td>${item.totalAmount}</td>
                        <td>
                          <span className="badge badge-pill badge-soft-success">
                            {item.bookingStatus}
                          </span>
                        </td>
                        <td>
                          <i className="material-icons md-payment font-xxl text-muted mr-5" />{" "}
                          Mastercard
                        </td>
                        <td>
                          <Link to={`/order/${item.orderId}`} className="btn btn-xs">
                            {" "}
                            View details
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                  {/* <tr>
                    <td className="text-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="transactionCheck03"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="transactionCheck03"
                        />
                      </div>
                    </td>
                    <td>
                      <a href="/" className="fw-bold">
                        #SK2541
                      </a>
                    </td>
                    <td>Jamal Burnett</td>
                    <td>07 Oct, 2021</td>
                    <td>$380</td>
                    <td>
                      <span className="badge badge-pill badge-soft-danger">
                        Chargeback
                      </span>
                    </td>
                    <td>
                      <i className="material-icons md-payment font-xxl text-muted mr-5" />{" "}
                      Visa
                    </td>
                    <td>
                      <a href="/" className="btn btn-xs">
                        {" "}
                        View details
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="transactionCheck04"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="transactionCheck04"
                        />
                      </div>
                    </td>
                    <td>
                      <a href="/" className="fw-bold">
                        #SK2542
                      </a>
                    </td>
                    <td>Juan Mitchell</td>
                    <td>06 Oct, 2021</td>
                    <td>$384</td>
                    <td>
                      <span className="badge badge-pill badge-soft-success">
                        Paid
                      </span>
                    </td>
                    <td>
                      <i className="material-icons md-payment font-xxl text-muted mr-5" />{" "}
                      Paypal
                    </td>
                    <td>
                      <a href="/" className="btn btn-xs">
                        {" "}
                        View details
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="transactionCheck05"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="transactionCheck05"
                        />
                      </div>
                    </td>
                    <td>
                      <a href="/" className="fw-bold">
                        #SK2543
                      </a>
                    </td>
                    <td>Barry Dick</td>
                    <td>05 Oct, 2021</td>
                    <td>$412</td>
                    <td>
                      <span className="badge badge-pill badge-soft-success">
                        Paid
                      </span>
                    </td>
                    <td>
                      <i className="material-icons md-payment font-xxl text-muted mr-5" />{" "}
                      Mastercard
                    </td>
                    <td>
                      <a href="/" className="btn btn-xs">
                        {" "}
                        View details
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="transactionCheck06"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="transactionCheck06"
                        />
                      </div>
                    </td>
                    <td>
                      <a href="/" className="fw-bold">
                        #SK2544
                      </a>
                    </td>
                    <td>Ronald Taylor</td>
                    <td>04 Oct, 2021</td>
                    <td>$404</td>
                    <td>
                      <span className="badge badge-pill badge-soft-warning">
                        Refund
                      </span>
                    </td>
                    <td>
                      <i className="material-icons md-payment font-xxl text-muted mr-5" />{" "}
                      Visa
                    </td>
                    <td>
                      <a href="/" className="btn btn-xs">
                        {" "}
                        View details
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="transactionCheck07"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="transactionCheck07"
                        />
                      </div>
                    </td>
                    <td>
                      <a href="/" className="fw-bold">
                        #SK2545
                      </a>
                    </td>
                    <td>Jacob Hunter</td>
                    <td>04 Oct, 2021</td>
                    <td>$392</td>
                    <td>
                      <span className="badge badge-pill badge-soft-success">
                        Paid
                      </span>
                    </td>
                    <td>
                      <i className="material-icons md-payment font-xxl text-muted mr-5" />{" "}
                      Paypal
                    </td>
                    <td>
                      <a href="/" className="btn btn-xs">
                        {" "}
                        View details
                      </a>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
          {/* table-responsive end// */}
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={orders.totalPages}
        onPageChange={handlePageChange}
      />
    </Layout>
  );
}

export default Orders;
