import React from "react";

type imageProps = {
  src: string;
  alt?: string;
  onDelete?: () => void;
  deleteShow?: boolean;
};
function Image({ src, onDelete, alt = "pic", deleteShow = true }: imageProps) {
  return (
    <div className="card mx-2" style={{ height: "100px", width: "100px" }}>
      {deleteShow && (
        <button type="button" className="btn delete-image" onClick={onDelete}>
          <i
            style={{ color: "red" }}
            className="icon material-icons md-remove_circle "
          ></i>
        </button>
      )}
      <img
        style={{
          height: "100%",
          width: "100%",
          objectFit: "contain",
        }}
        src={src}
        alt={alt}
      />
    </div>
  );
}

export default Image;
