import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { useFormik } from "formik";
import { requestHandler, uploadFileHandler } from "../../utils/requestHandler";
import Image from "../../components/elements/Image";
import { IMG_BASE_URL } from "../../utils/constants";
import {
  AboutusCardContentsI,
  AboutusI,
  ClipArtPrintingPrice,
  DesignCategoryI,
  DesignI,
  DesignResponseI,
  PrintingMethodI,
  ShopDetailsI,
} from "../../interfaces/productInterfaces";
import useGet from "../../hooks/useGet";
import Pagination from "../../components/elements/Pagination";
import Editor from "../../components/elements/Editor";
function ShopDetails() {
  const [aboutus, setAboutus] = useState<ShopDetailsI>({
    footerDescription: "",
    contact: "",
    email: "",
    openHours: "",
    address: "",
  });

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let data = new FormData();
      data.append("file", e.target.files[0]);
      data.append("path", "aboutus/images");

      uploadFileHandler<string>({
        endpoint: "/api/v1/upload",
        body: data,
      })
        .then((response) => {
          if (response.data) {
            setAboutus((prev) => ({
              ...prev,
              image: `${IMG_BASE_URL}/${response.data}`,
            }));
          }
          // formik.setFieldValue("image", );
        })
        .catch((err) => {
          alert(err.message || "Error uploading in image try again!!");
        });

      // console.log(response.data);
    }
  };

  const getAllDesigns = useCallback(async () => {
    requestHandler<ShopDetailsI>({
      endpoint: `/api/v1/shop-details`,
      method: "GET",
    })
      .then((res) => {
        if (res.success) {
          let data = res.data;
          setAboutus((prev) => ({
            footerDescription: data.footerDescription,
            address: data.address,
            contact: data.contact,
            openHours: data.openHours,
            email: data.email,
          }));
          // setAboutus((prev) => ({
          //   aboutusTitle: data.aboutusTitle,
          //   aboutusDescription: data.aboutusDescription,
          //   image: data.image,
          //   cardContentTitle: data.cardContentTitle,
          // }));
          // setCardContents(res.data.cardContents || []);
        }
      })
      .catch((err) => {
        // alert(err.message);
      });
  }, []);

  useEffect(() => {
    getAllDesigns();
  }, [getAllDesigns]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    requestHandler({
      endpoint: "/api/v1/shop-details",
      method: "PUT",
      body: {
        ...aboutus,
      },
    })
      .then((res) => {
        if (res.success) {
          getAllDesigns();
          alert(res.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  return (
    <Layout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">Shop Details</h2>
            <p>Add, edit or delete a Shop Details</p>
          </div>
          {/* <div>
          <input
            type="text"
            placeholder="Search Categories"
            className="form-control bg-white"
          />
        </div> */}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 mb-4">
                  <label htmlFor="footerDescription" className="form-label">
                    Footer Description
                  </label>
                  <input
                    type="text"
                    placeholder="Type here"
                    className="form-control"
                    id="footerDescription"
                    name="footerDescription"
                    value={aboutus.footerDescription}
                    onChange={(e) =>
                      setAboutus((prev) => ({
                        ...prev,
                        footerDescription: e.target.value,
                      }))
                    }
                    // onChange={formik.handleChange}
                  />
                </div>

                {/* <div className="mb-4">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <textarea
                      placeholder="Type here"
                      className="form-control"
                      id="description"
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                    />
                  </div> */}

                <div className="col-md-12 mb-4">
                  <label htmlFor="address" className="form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    placeholder="Type here"
                    className="form-control"
                    id="address"
                    name="address"
                    value={aboutus.address}
                    onChange={(e) =>
                      setAboutus((prev) => ({
                        ...prev,
                        address: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="col-md-12 mb-4">
                  <label htmlFor="contact" className="form-label">
                    Contact
                  </label>
                  <input
                    type="text"
                    placeholder="Type here"
                    className="form-control"
                    id="contact"
                    name="contact"
                    value={aboutus.contact}
                    onChange={(e) =>
                      setAboutus((prev) => ({
                        ...prev,
                        contact: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="col-md-12 mb-4">
                  <label htmlFor="openHours" className="form-label">
                    Open Hours
                  </label>
                  <input
                    type="text"
                    placeholder="Type here"
                    className="form-control"
                    id="openHours"
                    name="openHours"
                    value={aboutus.openHours}
                    onChange={(e) =>
                      setAboutus((prev) => ({
                        ...prev,
                        openHours: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="col-md-12 mb-4">
                  <label htmlFor="email" className="form-label">
                   Email
                  </label>
                  <input
                    type="text"
                    placeholder="Type here"
                    className="form-control"
                    id="email"
                    name="email"
                    value={aboutus.email}
                    onChange={(e) =>
                      setAboutus((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                  />
                </div>
                {/* .col// */}
              </div>

              <div className="">
                <div>
                  <button type="submit" className="btn btn-primary">
                    Update
                    {/* {edit.editEnable ? "Update About us" : "Create About us"} */}
                  </button>
                </div>
              </div>
              {/* .row // */}
            </div>

            {/* card body .// */}
          </div>
        </form>

        {/* card .// */}
      </section>
    </Layout>
  );
}

export default ShopDetails;
