import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { useFormik } from "formik";
import { requestHandler, uploadFileHandler } from "../../utils/requestHandler";
import Image from "../../components/elements/Image";
import { IMG_BASE_URL } from "../../utils/constants";
import { CouponI } from "../../interfaces/productInterfaces";
import { dateFormat } from "../../utils/convert";
function Coupons() {
  const [coupons, setCoupons] = useState<CouponI[]>([]);
  const [edit, setEdit] = useState({
    editEnable: false,
    editId: "",
  });
  const formik = useFormik({
    initialValues: {
      code: "",
      discountType: "percentage",
      discountValue: 0,
      usageLimit: 1,
      expirationDate: "",
      minPurchaseAmount: 0,
    },
    onSubmit: (values, { resetForm }) => {
      if (!edit.editEnable) {
        requestHandler({
          endpoint: "/api/v1/coupons",
          method: "POST",
          body: {
            ...values,
          },
        })
          .then((res) => {
            if (res.success) {
              getAllCoupons();
              alert(res.message);
              resetForm();
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      } else {
        requestHandler({
          endpoint: `/api/v1/coupons/${edit.editId}`,
          method: "PUT",
          body: {
            ...values,
          },
        })
          .then((res) => {
            if (res.success) {
              getAllCoupons();
              alert(res.message);
              resetForm();
              setEdit({ editEnable: false, editId: "" });
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      }
    },
  });

  const deleteImage = () => {
    formik.setFieldValue("imageUrl", "");
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let data = new FormData();
      data.append("file", e.target.files[0]);
      data.append("path", "coupons/images");

      uploadFileHandler<string>({
        endpoint: "/api/v1/upload",
        body: data,
      })
        .then((response) => {
          if (response.data)
            formik.setFieldValue(
              "imageUrl",
              `${IMG_BASE_URL}/${response.data}`
            );
        })
        .catch((err) => {
          alert(err.message || "Error uploading in image try again!!");
        });

      // console.log(response.data);
    }
  };

  const handleDelete = async (id: string) => {
    requestHandler({
      endpoint: `/api/v1/coupons/${id}`,
      method: "DELETE",
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          getAllCoupons();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const getAllCoupons = useCallback(async () => {
    requestHandler<CouponI[]>({
      endpoint: "/api/v1/coupons",
      method: "GET",
    })
      .then((res) => {
        if (res.success) {
          setCoupons(res.data);
        }
      })
      .catch((err) => {
        // alert(err.message);
      });
  }, []);

  useEffect(() => {
    getAllCoupons();
  }, [getAllCoupons]);
  return (
    <Layout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">Coupons</h2>
            <p>Add, edit or delete a Coupons</p>
          </div>
          {/* <div>
            <input
              type="text"
              placeholder="Search Categories"
              className="form-control bg-white"
            />
          </div> */}
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-4">
                    <label htmlFor="code" className="form-label">
                      Code
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      id="code"
                      name="code"
                      value={formik.values.code}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Discount Type</label>
                    <select
                      value={formik.values.discountType}
                      name="discountType"
                      className="form-select"
                      onChange={formik.handleChange}
                    >
                      <option value="percentage">Percentage</option>
                      <option value="fixed">Fixed</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="discountValue" className="form-label">
                      Discount value
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className="form-control"
                      id="discountValue"
                      name="discountValue"
                      value={formik.values.discountValue}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="expirationDate" className="form-label">
                      Valid Upto
                    </label>
                    <input
                      type="date"
                      placeholder="Type here"
                      className="form-control"
                      id="expirationDate"
                      name="expirationDate"
                      value={formik.values.expirationDate}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="usageLimit" className="form-label">
                      Usage Limit
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className="form-control"
                      id="usageLimit"
                      name="usageLimit"
                      value={formik.values.usageLimit}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="minPurchaseAmount" className="form-label">
                      Minimum Purchase Amount
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className="form-control"
                      id="minPurchaseAmount"
                      name="minPurchaseAmount"
                      value={formik.values.minPurchaseAmount}
                      onChange={formik.handleChange}
                    />
                  </div>

                  {/* <div className="mb-4">
                      <label htmlFor="description" className="form-label">
                        Description
                      </label>
                      <textarea
                        placeholder="Type here"
                        className="form-control"
                        id="description"
                        name="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                      />
                    </div> */}
                  {/* <div className="mb-4">
                    <label htmlFor="image" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      placeholder="Type here"
                      className="form-control"
                      id="image"
                      accept="image/*"
                      onChange={onFileChange}
                    />
                  </div> */}
                  {/* {formik.values.imageUrl && (
                    <Image
                      src={formik.values.imageUrl}
                      onDelete={deleteImage}
                    />
                  )} */}

                  <div className="d-grid text-center">
                    <button type="submit" className="btn btn-primary">
                      {edit.editEnable ? "Update Coupon" : "Create Coupon"}
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-md-9">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                            />
                          </div>
                        </th>
                        <th>S.No</th>
                        <th>Code</th>
                        {/* <th>Description</th> */}

                        <th>Valid Upto</th>
                        <th>Discount Type</th>
                        <th>Discount Value</th>
                        <th>Usage Limit</th>
                        <th>Minimum Purchase</th>
                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {coupons.map((coupon, i) => {
                        return (
                          <tr key={coupon._id}>
                            <td className="text-center">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                />
                              </div>
                            </td>
                            <td>{i + 1}</td>
                            <td>{coupon.code}</td>
                            {/* <td>{coupon.description}</td> */}
                            <td>
                              {dateFormat(coupon.expirationDate)}
                              {/* <Image deleteShow={false} src={coupon.imageUrl} /> */}
                            </td>
                            <td>{coupon.discountType}</td>
                            <td>{coupon.discountValue}</td>
                            <td>{coupon.usageLimit}</td>
                            <td>{coupon.minPurchaseAmount}</td>
                            <td className="text-end">
                              <div className="dropdown">
                                <a
                                  href="#"
                                  data-bs-toggle="dropdown"
                                  className="btn btn-light rounded btn-sm font-sm"
                                >
                                  {" "}
                                  <i className="material-icons md-more_horiz" />{" "}
                                </a>
                                <div className="dropdown-menu">
                                  {/* <div
                                    className="dropdown-item"
                                    onClick={() =>
                                      viewSubCategory(category.slug)
                                    }
                                  >
                                    View/Add Subcategories
                                  </div> */}
                                  <div
                                    className="dropdown-item"
                                    onClick={() => {
                                      formik.setFieldValue("code", coupon.code);
                                      formik.setFieldValue(
                                        "minPurchaseAmount",
                                        coupon.minPurchaseAmount
                                      );
                                      formik.setFieldValue(
                                        "usageLimit",
                                        coupon.usageLimit
                                      );
                                      formik.setFieldValue(
                                        "expirationDate",
                                        dateFormat(coupon.expirationDate)
                                      );

                                      formik.setFieldValue(
                                        "discountType",
                                        coupon.discountType
                                      );
                                      formik.setFieldValue(
                                        "discountValue",
                                        coupon.discountValue
                                      );
                                      setEdit((prev) => ({
                                        ...prev,
                                        editEnable: true,
                                        editId: coupon._id,
                                      }));
                                    }}
                                  >
                                    Edit info
                                  </div>
                                  <div
                                    className="dropdown-item text-danger"
                                    onClick={() => handleDelete(coupon._id)}
                                  >
                                    Delete
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* .col// */}
            </div>
            {/* .row // */}
          </div>
          {/* card body .// */}
        </div>
        {/* card .// */}
      </section>
    </Layout>
  );
}

export default Coupons;
