import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { useFormik } from "formik";
import { requestHandler, uploadFileHandler } from "../../utils/requestHandler";
import Image from "../../components/elements/Image";
import { IMG_BASE_URL } from "../../utils/constants";
import {
  DealsI,
  PrintingMethodI,
  SearchedProduct,
} from "../../interfaces/productInterfaces";
import { dateFormat } from "../../utils/convert";

function Deals() {
  const [materials, setMaterials] = useState<DealsI[]>([]);
  const [searchedProduct, setSearchedProduct] = useState<SearchedProduct[]>([]);
  const [selectedProduct, setSelectedProduct] = useState("");

  const [edit, setEdit] = useState({
    editEnable: false,
    editId: "",
  });
  const formik = useFormik({
    initialValues: {
      product: "",
      endDate: "",
    },
    onSubmit: (values, { resetForm }) => {
      if (!edit.editEnable) {
        requestHandler({
          endpoint: "/api/v1/deals",
          method: "POST",
          body: {
            ...values,
          },
        })
          .then((res) => {
            if (res.success) {
              getAllMaterials();
              alert(res.message);
              resetForm();
              setSelectedProduct("");
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      } else {
        requestHandler({
          endpoint: `/api/v1/deals/${edit.editId}`,
          method: "PUT",
          body: {
            ...values,
          },
        })
          .then((res) => {
            if (res.success) {
              getAllMaterials();
              alert(res.message);
              resetForm();
              setEdit({ editEnable: false, editId: "" });
              setSelectedProduct("");
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      }
    },
  });

  const deleteImage = () => {
    formik.setFieldValue("imageUrl", "");
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let data = new FormData();
      data.append("file", e.target.files[0]);
      data.append("path", "materials/images");

      uploadFileHandler<string>({
        endpoint: "/api/v1/upload",
        body: data,
      })
        .then((response) => {
          if (response.data)
            formik.setFieldValue(
              "methodImage",
              `${IMG_BASE_URL}/${response.data}`
            );
        })
        .catch((err) => {
          alert(err.message || "Error uploading in image try again!!");
        });

      // console.log(response.data);
    }
  };

  const handleDelete = async (id: string) => {
    requestHandler({
      endpoint: `/api/v1/deals/${id}`,
      method: "DELETE",
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          getAllMaterials();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const updateDealStatus = async (id: string, status: boolean) => {
    requestHandler({
      endpoint: `/api/v1/deals/update-main-deal/${id}`,
      method: "PUT",
      body: { status },
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          getAllMaterials();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const getAllMaterials = useCallback(async () => {
    requestHandler<DealsI[]>({
      endpoint: "/api/v1/deals",
      method: "GET",
    })
      .then((res) => {
        if (res.success) {
          setMaterials(res.data);
        }
      })
      .catch((err) => {
        // alert(err.message);
      });
  }, []);

  useEffect(() => {
    getAllMaterials();
  }, [getAllMaterials]);

  const searchHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) return setSearchedProduct([]);

    requestHandler<SearchedProduct[]>({
      endpoint: `/api/v1/search?query=${e.target.value}`,
      method: "GET",
    })
      .then((res) => {
        setSearchedProduct(res.data);
      })
      .catch((err) => {
        setSearchedProduct([]);
      });
  };
  return (
    <Layout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">Deals</h2>
            <p>Add, edit or delete a deals</p>
          </div>
          {/* <div>
          <input
            type="text"
            placeholder="Search Categories"
            className="form-control bg-white"
          />
        </div> */}
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-4">
                    <label htmlFor="title" className="form-label">
                      Name
                    </label>

                    {selectedProduct ? (
                      <div>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            formik.setFieldValue("product", "");
                            setSelectedProduct("");
                          }}
                        >
                          {selectedProduct}
                          <i className="icon material-icons md-close" />{" "}
                        </button>
                      </div>
                    ) : (
                      <>
                        <input
                          type="text"
                          placeholder="Search Product"
                          className="form-control"
                          id="title"
                          name="title"
                          onChange={searchHandle}

                          //   value={formik.values.title}
                          //   onChange={formik.handleChange}
                        />
                        {searchedProduct.length > 0 && (
                          <div className="search-list">
                            {searchedProduct.map((product) => {
                              return (
                                <div
                                  key={product._id}
                                  className="search-product"
                                  onClick={() => {
                                    formik.setFieldValue(
                                      "product",
                                      product._id
                                    );
                                    setSelectedProduct(product.name);
                                    //   setSelectedProduct((prev) => [...prev, product]);
                                    setSearchedProduct([]);
                                  }}
                                >
                                  <img
                                    src={product.image as string}
                                    alt={product.name}
                                  />{" "}
                                  {product.name}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="mb-4">
                    <label htmlFor="endDate" className="form-label">
                      End Date
                    </label>
                    <input
                      type="date"
                      placeholder="Type here"
                      className="form-control"
                      id="endDate"
                      name="endDate"
                      value={formik.values.endDate}
                      onChange={formik.handleChange}
                    />
                  </div>

                  {/* <div className="mb-4">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <textarea
                      placeholder="Type here"
                      className="form-control"
                      id="description"
                      name="methodDescription"
                      value={formik.values.methodDescription}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="image" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      placeholder="Type here"
                      className="form-control"
                      id="image"
                      accept="image/*"
                      onChange={onFileChange}
                    />
                  </div>
                  {formik.values.methodImage && (
                    <Image
                      src={formik.values.methodImage}
                      onDelete={deleteImage}
                    />
                  )} */}

                  <div className="d-grid text-center">
                    <button type="submit" className="btn btn-primary">
                      {edit.editEnable ? "Update Deal" : "Create Deal"}
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-md-9">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                            />
                          </div>
                        </th>
                        <th>S.No</th>
                        <th>Name</th>

                        <th>End Date</th>
                        <th>is Main Deal</th>

                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {materials.map((material, i) => {
                        return (
                          <tr key={material._id}>
                            <td className="text-center">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                />
                              </div>
                            </td>
                            <td>{i + 1}</td>
                            <td>{material.product.name}</td>
                            <td>{dateFormat(material.endDate)}</td>
                            <td>{material.mainDeal ? "Yes" : "NO"}</td>
                            {/* <td>
                              <Image
                                deleteShow={false}
                                src={material.methodImage}
                              />
                            </td> */}

                            <td className="text-end">
                              <div className="dropdown">
                                <a
                                  href="#"
                                  data-bs-toggle="dropdown"
                                  className="btn btn-light rounded btn-sm font-sm"
                                >
                                  {" "}
                                  <i className="material-icons md-more_horiz" />{" "}
                                </a>
                                <div className="dropdown-menu">
                                  {/* <div
                                  className="dropdown-item"
                                  onClick={() =>
                                    viewSubCategory(category.slug)
                                  }
                                >
                                  View/Add Subcategories
                                </div> */}
                                  <div
                                    className="dropdown-item"
                                    onClick={() => {
                                      formik.setFieldValue(
                                        "product",
                                        material.product._id
                                      );
                                      formik.setFieldValue(
                                        "endDate",
                                        dateFormat(material.endDate)
                                      );
                                      setSelectedProduct(material.product.name);
                                      setEdit((prev) => ({
                                        ...prev,
                                        editEnable: true,
                                        editId: material._id,
                                      }));
                                    }}
                                  >
                                    Edit info
                                  </div>
                                  <div
                                    className="dropdown-item text-danger"
                                    onClick={() => handleDelete(material._id)}
                                  >
                                    Delete
                                  </div>
                                  <div
                                    className="dropdown-item text-danger"
                                    onClick={() =>
                                      updateDealStatus(
                                        material._id,
                                        !material.mainDeal
                                      )
                                    }
                                  >
                                    Update Main Deal Status
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* .col// */}
            </div>
            {/* .row // */}
          </div>
          {/* card body .// */}
        </div>
        {/* card .// */}
      </section>
    </Layout>
  );
}

export default Deals;
