import React, { useCallback, useEffect, useState } from "react";
import Pagination from "../../components/elements/Pagination";
import Layout from "../../components/layout/Layout";
import { UserResponse } from "../../interfaces/userInterfaces";
import { requestHandler } from "../../utils/requestHandler";

function Users() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [usersResponse, setUsersResponse] = useState<UserResponse>({
    users: [],
    totalPages: 0,
    totalUsers: 0,
  });
  const [loading, setLoading] = useState(false);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    requestHandler<UserResponse>({
      endpoint: `/api/v1/admin/user?page=${currentPage}&limit=10`,
      method: "GET",
    })
      .then((res) => {
        if (res.success) {
          setUsersResponse(res.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [currentPage]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const changeStatus = async (userId:string,status: boolean) => {
    requestHandler({
      endpoint: `/api/v1/admin/user/${userId}`,
      method: "PUT",
      body: { status },
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          fetchUsers();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  return (
    <Layout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">Users </h2>
            {/* <p>Lorem ipsum dolor sit amet.</p> */}
          </div>
          {/* <div>
            <button
              className="btn btn-primary btn-sm rounded"
              // onClick={() => navigate("/create-product")}
            >
              Create new
            </button>
          </div> */}
        </div>
        <div className="card mb-4">
          {/* <header className="card-header">
          <div className="row gx-3">
            <div className="col-lg-4 col-md-6 me-auto">
              <input
                type="text"
                placeholder="Search..."
                className="form-control"
              />
            </div>
            <div className="col-lg-2 col-6 col-md-3">
              <select className="form-select">
                <option>All category</option>
                <option>Electronics</option>
                <option>Clothings</option>
                <option>Something else</option>
              </select>
            </div>
            <div className="col-lg-2 col-6 col-md-3">
              <select className="form-select">
                <option>Latest added</option>
                <option>Cheap first</option>
                <option>Most viewed</option>
              </select>
            </div>
          </div>
        </header> */}
          {/* card-header end// */}
          <div className="card-body">
            {/* <div className="row gx-3 "> */}

            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th className="text-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                        />
                      </div>
                    </th>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone No.</th>

                    <th>Status</th>
                    <th className="text-end">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {usersResponse.users.map((user, i) => {
                    return (
                      <tr key={user._id}>
                        <td className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                            />
                          </div>
                        </td>
                        <td>{i + 1}</td>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.phone}</td>

                        <td>
                          {user.status ? (
                            <span className="text-success">Active</span>
                          ) : (
                            <span className="text-danger">Deactive</span>
                          )}
                        </td>

                        <td className="text-end">
                          <div className="dropdown">
                            <a
                              href="#"
                              data-bs-toggle="dropdown"
                              className="btn btn-light rounded btn-sm font-sm"
                            >
                              {" "}
                              <i className="material-icons md-more_horiz" />{" "}
                            </a>
                            <div className="dropdown-menu">
                              <div
                                className="dropdown-item"
                                onClick={() =>
                                    changeStatus(user._id,!user.status)
                                }
                              >
                                Change User Status
                              </div>
                              {/* <div
                                className="dropdown-item"
                                onClick={() => {
                                  formik.setFieldValue(
                                    "categoryName",
                                    category.categoryName
                                  );
                                  formik.setFieldValue(
                                    "image",
                                    category.image
                                  );
                                  setEdit((prev) => ({
                                    ...prev,
                                    editEnable: true,
                                    editId: category._id,
                                  }));
                                // }}
                              >
                                Edit info
                              </div> */}
                              {/* <div
                                className="dropdown-item text-danger"
                                // onClick={() => handleDelete(category._id)}
                              >
                                Delete
                              </div> */}
                            </div>
                          </div>
                          {/* dropdown //end */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {/* {productResponse.products.length ===0 && <p>No Products found</p>} */}

            {/* col.// */}
          </div>
          {/* row.// */}
        </div>
        {/* card-body end// */}
        {/* </div> */}
        {/* card end// */}
        <Pagination
          currentPage={currentPage}
          totalPages={usersResponse.totalPages}
          onPageChange={handlePageChange}
        />
      </section>
    </Layout>
  );
}

export default Users;
