import React, { useState } from "react";
import Layout from "../../components/layout/Layout";
import useCategory from "../../hooks/useCategory";
import { useFormik } from "formik";
import { requestHandler } from "../../utils/requestHandler";
import Loader from "../../components/elements/Loader";
function InkedJoyProducts() {
  const { categories } = useCategory();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      url: "",
      categoryId: "",
      isCustomizable: "yes",
    },
    onSubmit: (values, { resetForm }) => {
      // return;
      setLoading(true);
      requestHandler({
        endpoint: "/api/v1/scripts/inkedjoy",
        method: "POST",
        body: { ...values, isCustomizable: values.isCustomizable === "yes" },
      })
        .then((res) => {
          if (res.success) {
            alert(res.message);
            resetForm();
          }
        })
        .catch((err) => {
          alert(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <Layout>
      {loading && <Loader />}
      <section className="content-main">
        <div className="row">
          <div className="col-12">
            <div className="content-header">
              <h2 className="content-title">
                Add Products From inkedjoy websites{" "}
              </h2>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="card mb-4">
              <div className="card-body">
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-4">
                    <label htmlFor="url" className="form-label">
                      URL
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      name="url"
                      value={formik.values.url}
                      onChange={formik.handleChange}
                    />
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <label className="form-label">Category</label>
                      <select
                        className="form-select"
                        name="categoryId"
                        onChange={formik.handleChange}
                        value={formik.values.categoryId}
                      >
                        <option disabled value={""}>
                          Select
                        </option>
                        {categories.map((category) => {
                          return (
                            <option value={category._id} key={category._id}>
                              {category.categoryName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-12">
                      <label className="form-label">Is customizable</label>
                      <select
                        className="form-select"
                        name="isCustomizable"
                        onChange={formik.handleChange}
                        value={formik.values.isCustomizable}
                      >
                        <option value="yes">yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>

                  <button className="btn btn-md rounded font-sm hover-up mt-3">
                    Scrap
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default InkedJoyProducts;
