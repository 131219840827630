import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { useFormik } from "formik";
import { requestHandler, uploadFileHandler } from "../../utils/requestHandler";
import Image from "../../components/elements/Image";
import { IMG_BASE_URL } from "../../utils/constants";
import {
  PaymentModuleI,
  PrintingMethodI,
} from "../../interfaces/productInterfaces";
import useGet from "../../hooks/useGet";

function PaymentModule() {
  const [materials, setMaterials] = useState<PaymentModuleI[]>([]);

  const printingMethods = useGet<PrintingMethodI>("/api/v1/printing-method");
  const [edit, setEdit] = useState({
    editEnable: false,
    editId: "",
  });
  const formik = useFormik({
    initialValues: {
      printingMethod: "",
      designType: "",
      price: "",
    },
    onSubmit: (values, { resetForm }) => {
      if (!edit.editEnable) {
        requestHandler({
          endpoint: "/api/v1/payment-modules",
          method: "POST",
          body: {
            ...values,
          },
        })
          .then((res) => {
            if (res.success) {
              getAllMaterials();
              alert(res.message);
              resetForm();
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      } else {
        requestHandler({
          endpoint: `/api/v1/payment-modules/${edit.editId}`,
          method: "PUT",
          body: {
            ...values,
          },
        })
          .then((res) => {
            if (res.success) {
              getAllMaterials();
              alert(res.message);
              resetForm();
              setEdit({ editEnable: false, editId: "" });
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      }
    },
  });

  const handleDelete = async (id: string) => {
    requestHandler({
      endpoint: `/api/v1/payment-modules/${id}`,
      method: "DELETE",
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          getAllMaterials();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const getAllMaterials = useCallback(async () => {
    requestHandler<PaymentModuleI[]>({
      endpoint: "/api/v1/payment-modules",
      method: "GET",
    })
      .then((res) => {
        if (res.success) {
          setMaterials(res.data);
        }
      })
      .catch((err) => {
        // alert(err.message);
      });
  }, []);

  useEffect(() => {
    getAllMaterials();
  }, [getAllMaterials]);
  return (
    <Layout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">Payment Module</h2>
            <p>Add, edit or delete a Methods</p>
          </div>
          {/* <div>
          <input
            type="text"
            placeholder="Search Categories"
            className="form-control bg-white"
          />
        </div> */}
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-4">
                    <label className="form-label">Printing Method</label>
                    <select
                      value={formik.values.printingMethod}
                      name="printingMethod"
                      className="form-select"
                      onChange={formik.handleChange}
                    >
                      <option value={""}>select</option>
                      {printingMethods.data.map((item) => {
                        return (
                          <option key={item._id} value={item._id}>
                            {item.methodName}
                          </option>
                        );
                      })}
                      {/* <option value="fixed">Fixed</option> */}
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Design Type</label>
                    <select
                      value={formik.values.designType}
                      name="designType"
                      className="form-select"
                      onChange={formik.handleChange}
                    >
                      <option value={""}>select</option>
                      <option value="Text">Text</option>
                      <option value="Draw">Draw</option>
                      <option value="Upload">Upload</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="price" className="form-label">
                      Price
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className="form-control"
                      id="price"
                      name="price"
                      value={formik.values.price}
                      onChange={formik.handleChange}
                    />
                  </div>

                  <div className="d-grid text-center">
                    <button type="submit" className="btn btn-primary">
                      {edit.editEnable ? "Update Methods" : "Create Methods"}
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-md-9">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                            />
                          </div>
                        </th>
                        <th>S.No</th>
                        <th>Printing Type</th>
                        <th>Design Type</th>
                        <th>Price</th>

                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {materials.map((material, i) => {
                        return (
                          <tr key={material._id}>
                            <td className="text-center">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                />
                              </div>
                            </td>
                            <td>{i + 1}</td>
                            <td>{material.printingMethod.methodName}</td>
                            <td>{material.designType}</td>
                            <td>{material.price}</td>


                            <td className="text-end">
                              <div className="dropdown">
                                <a
                                  href="#"
                                  data-bs-toggle="dropdown"
                                  className="btn btn-light rounded btn-sm font-sm"
                                >
                                  {" "}
                                  <i className="material-icons md-more_horiz" />{" "}
                                </a>
                                <div className="dropdown-menu">
                                  {/* <div
                                  className="dropdown-item"
                                  onClick={() =>
                                    viewSubCategory(category.slug)
                                  }
                                >
                                  View/Add Subcategories
                                </div> */}
                                  <div
                                    className="dropdown-item"
                                    onClick={() => {
                                      formik.setFieldValue(
                                        "designType",
                                        material.designType
                                      );
                                      formik.setFieldValue(
                                        "printingMethod",
                                        material.printingMethod._id
                                      );
                                      formik.setFieldValue(
                                        "price",
                                        material.price
                                      );
                                      setEdit((prev) => ({
                                        ...prev,
                                        editEnable: true,
                                        editId: material._id,
                                      }));
                                    }}
                                  >
                                    Edit info
                                  </div>
                                  <div
                                    className="dropdown-item text-danger"
                                    onClick={() => handleDelete(material._id)}
                                  >
                                    Delete
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* .col// */}
            </div>
            {/* .row // */}
          </div>
          {/* card body .// */}
        </div>
        {/* card .// */}
      </section>
    </Layout>
  );
}

export default PaymentModule;
