import React from "react";
import Swal from "sweetalert2";
import { requestHandler } from "../../utils/requestHandler";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import storage from "../../utils/storage";

function Header() {
  const navigate = useNavigate();
  const { token, setToken } = useAuth();

  const logout = () => {
    const auth = storage.get("auth");
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout ",
    }).then((result) => {
      if (result.isConfirmed) {
        requestHandler({
          endpoint: `/api/v1/auth/admin/logout`,
          method: "POST",
          body: { jwt: auth },
        })
          .then((res) => {
            setToken(null);
            navigate("/login");
            localStorage.clear()
          })
          .catch((err) => {
            alert(err.message);
          });
      }
    });
  };
  return (
    <header className="main-header navbar">
      <div className="col-search">
        <form className="searchform">
          <div className="input-group">
            <input
              list="search_terms"
              type="text"
              className="form-control"
              placeholder="Search term"
            />
            <button className="btn btn-light bg" type="button">
              <i className="material-icons md-search" />
            </button>
          </div>
          <datalist id="search_terms">
            <option value="Products" />
            <option value="New orders" />
            <option value="Apple iphone" />
            <option value="Ahmed Hassan" />
          </datalist>
        </form>
      </div>
      <div className="col-nav">
        <button
          className="btn btn-icon btn-mobile me-auto"
          data-trigger="#offcanvas_aside"
        >
          <i className="material-icons md-apps" />
        </button>
        <ul className="nav">
          <li className="nav-item">
            <a className="nav-link btn-icon" href="#">
              <i className="material-icons md-notifications animation-shake" />
              <span className="badge rounded-pill">3</span>
            </a>
          </li>
          <li className="nav-item">
            <div className="nav-link btn-icon darkmode">
              {" "}
              <i className="material-icons md-nights_stay" />{" "}
            </div>
          </li>
          <li className="nav-item">
            <a href="#" className="requestfullscreen nav-link btn-icon">
              <i className="material-icons md-cast" />
            </a>
          </li>

          <li className="dropdown nav-item">
            <a
              className="dropdown-toggle"
              data-bs-toggle="dropdown"
              href="#"
              id="dropdownAccount"
              aria-expanded="false"
            >
              {" "}
              <img
                className="img-xs rounded-circle"
                src="assets/imgs/people/avatar-2.png"
                alt="User"
              />
            </a>
            <div
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="dropdownAccount"
            >
              <div className="dropdown-item">
                <i className="material-icons md-perm_identity" />
                Edit Profile
              </div>
              <div className="dropdown-item">
                <i className="material-icons md-settings" />
                Account Settings
              </div>
              <div className="dropdown-item">
                <i className="material-icons md-account_balance_wallet" />
                Wallet
              </div>
              <div className="dropdown-item">
                <i className="material-icons md-receipt" />
                Billing
              </div>
              <div className="dropdown-item">
                <i className="material-icons md-help_outline" />
                Help center
              </div>
              <div className="dropdown-divider" />
              <div className="dropdown-item text-danger" onClick={logout}>
                <i className="material-icons md-exit_to_app" />
                Logout
              </div>
            </div>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;
